import React, { useEffect, useRef, useState } from "react";
import {
  GET_ORGANISATION,
  GET_QUESTION_BANK,
  GET_ROLES,
  GET_TASKS,
  GET_USERS,
} from "../../../../action/apiPath";
import { CallWithAuth } from "../../../../action/apiActions";
import FilterModal from "../../../atoms/Modal/FilterModal";
import MastersAddModal from "../../../atoms/Modal/MastersAddModal";

import style from "../../../../assets/styles/taskManagement.module.css";
import styleModal from "../../../../assets/styles/modalStyle.module.css";
import moment from "moment";
import {
  Approve_Task,
  Delete_Task,
  Get_Task_Detail,
  Task_Screen_Change,
} from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../../atoms/Modal/ConfirmModal";
import TaskFilterModal from "../../../atoms/Modal/TaskFilterModal";

const TaskDetail = ({ setScreen, taskDetailFunction }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
     setIsModalOpen(!isModalOpen);
  };

  const dispatch = useDispatch();
  const { taskDetail } = useSelector((state) => state.GetAllData);

  // console.log("detail", taskDetail?.assignees);

  const questionID = taskDetail?.question_bank_questions?.map(
    (item) => item.id
  );
  // console.log("My question ID is", questionID);

  const [questionListing, setQuestionListing] = useState([]);

  /*** Question list API */
  const questionList = async () => {
    const offset = 0;
    const limit = 30;
    const url =
      GET_QUESTION_BANK +
      questionID[0] +
      "/question-listing/?" +
      "offset=" +
      offset +
      "&limit=" +
      limit;
    // console.log("url", url);
    const questionlistingData = await CallWithAuth("GET", url);
    // console.log("questionlistingData", questionlistingData);
    if (questionlistingData.status && questionlistingData.res.status === 200) {
      setQuestionListing(questionlistingData.res.data.results);
    } else {
      console.error("Error fetching API");
    }
  };

  useEffect(() => {
    questionList();
  }, []);

  // _______________ roles get ______________
  const [roles, setRoles] = useState([]);

  const GetRoles = async () => {
    const GetData = await CallWithAuth("GET", GET_ROLES);
    // console.log("data", GetData);
    if (GetData.status && Array.isArray(GetData.res.data)) {
      setRoles(GetData.res.data);
    }
  };

  useEffect(() => {
    GetRoles();
  }, []);

  // ______________ oganisation ______________
  const [Organisation, setOrganisation] = useState([]);

  const getOrganisation = async () => {
    const GetData = await CallWithAuth("GET", GET_ORGANISATION);
    // console.log("data", GetData);
    if (GetData.status && Array.isArray(GetData.res.data)) {
      setOrganisation(GetData.res.data);
    }
  };

  useEffect(() => {
    getOrganisation();
  }, []);

  // ________ filter modal open  ________
  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  // const DeleteTask=async()=>{
  //     const data = await CallWithAuth("DELETE", GET_TASKS + id + "/delete_task/")
  //     if (data.status && data.res.status === 200) {
  //         taskDetailFunction()
  //         setScreen(0)
  //     }

  // }

  // const ApprovTask=async()=>{
  //     const data = await CallWithAuth("PATCH", GET_TASKS + id + "/approve/")
  //     if (data.status && data.res.status === 200) {
  //         taskDetailFunction()
  //         setScreen(0)
  //     }

  // }

  // console.log(taskDetail);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [ approveConfirmation, setApproveConfirmation] = useState(false)


  /*** DELETE */
  const handleDelete = () => {
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    dispatch(Delete_Task(taskDetail?.task_id));

    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

/****APPROVE */
  const handleApprove = () => {
    setApproveConfirmation(true)
  }

  const handleConfirmApprove = () => {
    dispatch(Approve_Task(taskDetail?.task_id))
    setApproveConfirmation(false);
  }

  const cancelApprove = () => {
    setApproveConfirmation(false)
  }


  /** APPROVE MODAL */
  const ApproveModal = ({ onApprove, onCancel,task_id}) => {
    // __________ outside clickable close modal _______________

    const modalRef = useRef();

    const handleOverlayClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCancelDelete()
      }
    };

    useEffect(() => {
      // Attach the event listener when the component mounts
      document.addEventListener('mousedown', handleOverlayClick);

      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener('mousedown', handleOverlayClick);
      };
    }, [handleOverlayClick]);

return (
  <div className={styleModal.filterModal}>
  <div className={` ${styleModal.center_div} ${styleModal.delete}`} ref={modalRef}>
    {/* TABLE LISTING OF ALL AND MY QUESTION  */}
    <div className={styleModal.master_modal}>
      <div className={styleModal.title_delete}>
        Are you sure, Do you want to Approve( Task  : {task_id} ) ?
      </div>

      <div className={` ${styleModal.saveButton_questionBank} ${styleModal.margin_top_20} ${styleModal.center}`}>
        <div className={` ${styleModal.cancel} ${styleModal.pointer}`} onClick={onCancel}>
          Cancel
        </div>
        <div>
          <button className={styleModal.blue_button} onClick={onApprove}>Approve</button>
        </div>
      </div>
    </div>
  </div>
</div>
);
};



  /*** DELETE MODAL */
  const ConfirmModal = ({ onConfirm, onCancel,task_id}) => {
        // __________ outside clickable close modal _______________

        const modalRef = useRef();

        const handleOverlayClick = (event) => {
          if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleCancelDelete()
          }
        };
    
        useEffect(() => {
          // Attach the event listener when the component mounts
          document.addEventListener('mousedown', handleOverlayClick);
    
          // Clean up the event listener when the component unmounts
          return () => {
            document.removeEventListener('mousedown', handleOverlayClick);
          };
        }, [handleOverlayClick]);

    return (
      <div className={styleModal.filterModal}>
      <div className={` ${styleModal.center_div} ${styleModal.delete}`} ref={modalRef}>
        {/* TABLE LISTING OF ALL AND MY QUESTION  */}
        <div className={styleModal.master_modal}>
          <div className={styleModal.title_delete}>
            Are you sure, Do you want to delete ( Task  : {task_id} ) ?
          </div>

          <div className={` ${styleModal.saveButton_questionBank} ${styleModal.margin_top_20} ${styleModal.center}`}>
            <div className={` ${styleModal.cancel} ${styleModal.pointer}`} onClick={onCancel}>
              Cancel
            </div>
            <div>
              <button className={styleModal.blue_button} onClick={onConfirm}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};


  return (
    <div >
      <div className={style.top_section}>
        <span
          className={style.left}
          onClick={() => dispatch(Task_Screen_Change(0))}
          style={{ cursor: "pointer" }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5488 19.422L9.02883 12.902C8.25883 12.132 8.25883 10.872 9.02883 10.102L15.5488 3.58203"
              stroke="#212529"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Back
        </span>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          
        }}
        className={`${style.table_content_report} ${style.flow}`}
      >
        <div className={style.break}>
          <div
            style={{ display: "flex", gap: "20px", alignItems: "center" }}
            className={style.profile_detail_div}
          >
            <span style={{ color: "#8E9EAB" }}>Task ID </span>
            <span>{taskDetail?.task_id}</span>

            <div
              className={
                taskDetail?.status ? `${style.activate}` : `${style.inactive}`
              }
            >
              {taskDetail?.status}
            </div>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "51em",
              }}
              onClick={() => dispatch(Task_Screen_Change(3))}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 22H21"
                  stroke="#00ABFB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.2603 3.59924L5.05034 12.2892C4.74034 12.6192 4.44034 13.2692 4.38034 13.7192L4.01034 16.9592C3.88034 18.1292 4.72034 18.9292 5.88034 18.7292L9.10034 18.1792C9.55034 18.0992 10.1803 17.7692 10.4903 17.4292L18.7003 8.73924C20.1203 7.23924 20.7603 5.52924 18.5503 3.43924C16.3503 1.36924 14.6803 2.09924 13.2603 3.59924Z"
                  stroke="#00ABFB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.8896 5.04883C12.3196 7.80883 14.5596 9.91883 17.3396 10.1988"
                  stroke="#00ABFB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              &nbsp; <u style={{ color: "#00ABFB", cursor:'pointer' }}>Edit</u>
            </span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginRight: "2em",
            }}
            className={` ${style.select_question} ${style.profile_status}`}
          >
            <div className={` ${style.question_bank_table} ${style.shadow}`}>
              <div
                className={` ${style.tbody_question_bank} ${style.profile_detail_info}`}
              >
                <div className={style.first}>
                  <span className={style.title_detail}> Assignee</span>
                  <br />
                  <span style={{display:'flex',flexWrap:'wrap',gap:'5px'}} className={style.description_detail}>
                    {taskDetail?.assignees.map((items, i) => (
                      <button className={style.general_button}>{items}</button>
                    ))}
                  </span>
                </div>
                <div className={style.second}>
                  <span  className={style.title_detail}> Approver</span>
                  <br />
                  <span style={{display:'flex',flexWrap:'wrap',gap:'5px'}} className={style.description_detail}>
                    {taskDetail?.approvers.map((items, i) => (
                      <button className={style.general_button}>{items}</button>
                    ))}
                  </span>
                </div>
                <div className={style.third}>
                  <span className={style.title_detail}> Created on</span>
                  <br />
                  <span className={style.description_detail}>
                    {" "}
                    {moment(taskDetail?.created_on).format("Do MMM YYYY")}
                  </span>
                </div>
                <div className={style.fouth}>
                  <span className={style.title_detail}> End Date</span>
                  <br />
                  <span className={style.description_detail}>
                    {" "}
                    {moment(taskDetail?.end_date).format("Do MMM YYYY")}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div  className={` ${style.select_question} ${style.profile_status}`}>
            <h1 className={style.title_detail}>Task details</h1>
            <p style={{marginBottom:'2em'}} className={style.description_detail}>
              {taskDetail?.task_details}
            </p>
          </div>
        </div>

        <div className={style.break_two}>
          <div className={style.profile_detail_div}>
            Question List
            <div className={style.filter} style={{ float: "right" }}>
              <div className={style.pointer} onClick={() => toggleModal()}>
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.39844 1.10156H16.5984C17.6984 1.10156 18.5984 2.00156 18.5984 3.10156V5.30156C18.5984 6.10156 18.0984 7.10156 17.5984 7.60156L13.2984 11.4016C12.6984 11.9016 12.2984 12.9016 12.2984 13.7016V18.0016C12.2984 18.6016 11.8984 19.4016 11.3984 19.7016L9.99844 20.6016C8.69844 21.4016 6.89844 20.5016 6.89844 18.9016V13.6016C6.89844 12.9016 6.49844 12.0016 6.09844 11.5016L2.29844 7.50156C1.79844 7.00156 1.39844 6.10156 1.39844 5.50156V3.20156C1.39844 2.00156 2.29844 1.10156 3.39844 1.10156Z"
                    stroke="#212529"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span>Filter</span>
              </div>
            </div>
          </div>
          <div
            className={` ${style.select_question} ${style.profile_status} ${style.question_list_of_task}`}
          >
            <div
              className={style.table_content_report}
              style={{ borderRadius: "0px" }}
            >
              {/* TABLE LISTING OF ALL AND MY QUESTION  */}
              <div className={style.sub_head_content_table}>
                <table>
                  <thead>
                    <tr>
                      <th className={` ${style.very_first} ${style.pointer}`}>
                        S No.
                      </th>
                      <th className={` ${style.very_first} ${style.pointer}`}>
                        Question ID and Tags
                      </th>

                      <th className={` ${style.second} ${style.pointer}`}>
                        {" "}
                        <span>Author</span>
                        <svg
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.third} ${style.pointer}`}>
                        <span>Created On</span>
                        <svg
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                      <th className={` ${style.fourth} ${style.pointer}`}>
                        <span>Question Bank</span>
                        <svg
                          width="19"
                          height="12"
                          viewBox="0 0 19 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                            fill="#00ABFB"
                          />
                          <path
                            d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                            fill="#00ABFB"
                          />
                        </svg>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {questionListing?.map((items, i) => (
                      <tr>
                        <td style={{ textAlign: "center", width: "50px" }}>
                          {i + 1}
                        </td>
                        <th className={style.id_tags_column}>
                          <div class={style.question_id_tag}>
                            <div class={style.id}> {items.question_id}</div>
                            <div class={style.tag}>
                              <p>{items.question_short_identifier}</p>
                              <span>{items.primary_tag.slug}</span>
                            </div>
                          </div>
                        </th>
                        <th>
                          <span>{items.created_by}</span>
                        </th>
                        <th>
                          <span>
                            {" "}
                            {moment(questionListing?.created_on).format(
                              "Do MMM YY"
                            )}
                          </span>
                        </th>
                        <th>
                          <span>{items.question_bank}</span>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "left" }}
        className={` ${style.saveButton_questionBank} ${style.margin_top_20}`}
      >
        <div
          className={` ${style.cancel} ${style.pointer}`}
          onClick={handleDelete}
        >
          Delete Task
        </div>
        <div>
          <button
            className={style.blue_button}
            onClick={handleApprove}
          >
            Approve Task
          </button>
        </div>
        {isModalOpen && <TaskFilterModal modal={isModalOpen} closeModal={toggleModal} />}

        {showConfirmation && (
          <ConfirmModal
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
            task_id={taskDetail?.task_id}
          />
        )}

        {approveConfirmation && (
          <ApproveModal
            onApprove={handleConfirmApprove}
            onCancel={cancelApprove}
            task_id={taskDetail?.task_id}
          />
        )}
        {/* <FilterModal questionCategory={questionCategory} QuestionListing={QuestionListing} MyQuestionListing={MyQuestionListing} modalFilter={modalFilter} closeModalFilter={closeModalFilter} multiselectedSubjectTag={multiselectedSubjectTag} setmultiSelectedSubjectTag={setmultiSelectedSubjectTag} multiselectedFreeTag={multiselectedFreeTag} setmultiSelectedFreeTag={setmultiSelectedFreeTag} multiselectedCategoryTag={multiselectedCategoryTag} setmultiSelectedCategoryTag={setmultiSelectedCategoryTag} multiselectedAuthorTag={multiselectedAuthorTag} setmultiSelectedAuthorTag={setmultiSelectedAuthorTag} selectedYear={selectedYear} setSelectedYear={setSelectedYear} selectedYearExclude={selectedYearExclude} setSelectedYearExclude={setSelectedYearExclude} currentYear={currentYear} /> */}
      </div>
    </div>
  );
};

export default TaskDetail;
