import React, { useEffect, useState } from "react";

import style from "../../../../assets/styles/userManagement.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
    GetUserDetail,
    GetOraganisationList,
    GetRoles,
    UserScreenChange,
} from "../../../../redux/action";

const UserDetail = () => {
    const dispatch = useDispatch();
    const { userDataInfo } = useSelector((state) => state.GetAllData);


    // ______________ roles ______________
    const { roles } = useSelector((state) => state.GetAllData);

    useEffect(() => {
        dispatch(GetRoles());
    }, []);

    // ______________ oganisation ______________
    const { organisationList } = useSelector((state) => state.GetAllData);

    useEffect(() => {
        dispatch(GetOraganisationList());
    }, []);

    return (
        <div>
            <div className={style.top_section}>
                <span className={style.left} onClick={() => dispatch(UserScreenChange(0))}>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M15.5488 19.422L9.02883 12.902C8.25883 12.132 8.25883 10.872 9.02883 10.102L15.5488 3.58203"
                            stroke="#212529"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    Back
                </span>
                <span className={style.right} onClick={() => dispatch(UserScreenChange(3))}>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3 22H21"
                            stroke="#00ABFB"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M13.2603 3.59924L5.05034 12.2892C4.74034 12.6192 4.44034 13.2692 4.38034 13.7192L4.01034 16.9592C3.88034 18.1292 4.72034 18.9292 5.88034 18.7292L9.10034 18.1792C9.55034 18.0992 10.1803 17.7692 10.4903 17.4292L18.7003 8.73924C20.1203 7.23924 20.7603 5.52924 18.5503 3.43924C16.3503 1.36924 14.6803 2.09924 13.2603 3.59924Z"
                            stroke="#00ABFB"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M11.8896 5.04883C12.3196 7.80883 14.5596 9.91883 17.3396 10.1988"
                            stroke="#00ABFB"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    &nbsp; <u>Edit</u>
                </span>
            </div>
            <div className={style.table_content_report}>
                <div className={style.profile_detail_div}>
                    User Profile
                    <div
                        className={
                            userDataInfo?.is_active
                                ? `${style.activate}`
                                : `${style.inactive}`
                        }
                    >
                        {userDataInfo?.is_active ? "active" : "inactive"}
                    </div>
                </div>

                <div className={` ${style.select_question} ${style.profile_status}`}>
                    <div className={` ${style.question_bank_table} ${style.shadow}`}>
                        <div
                            className={` ${style.tbody_question_bank} ${style.profile_detail_info}`}
                        >
                            <div className={style.first}>
                                <span className={style.title_detail}> Full Name</span>
                                <br />
                                <span className={style.description_detail}>
                                    {userDataInfo?.name}
                                </span>
                            </div>
                            <div className={style.second}>
                                <span className={style.title_detail}> Email ID</span>
                                <br />
                                <span className={style.description_detail}>
                                    {userDataInfo?.email_id}
                                </span>
                            </div>
                            <div className={style.third}>
                                <span className={style.title_detail}> Roles</span>
                                <br />
                                <span className={style.description_detail}>
                                    {roles
                                        ?.filter((item) => userDataInfo?.roles.includes(item.id))
                                        .map((items, i) => items.name + ",")}
                                </span>
                            </div>
                            <div className={style.fouth}>
                                <span className={style.title_detail}> Mobile Number</span>
                                <br />
                                <span className={style.description_detail}>
                                    {userDataInfo?.mobile_number}
                                </span>
                            </div>
                            <div className={style.last}>
                                <span className={style.title_detail}> Organisation</span>
                                <br />
                                <span className={style.description_detail}>
                                    {organisationList?.filter(
                                        (item) => item.id === userDataInfo?.organization
                                    ).map((items, i) => items.name)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={` ${style.select_question} ${style.profile_status}`}>
                    <div className={style.title}>
                        Question Bank management 
                    </div>

                    <div className={` ${style.question_bank_table} ${style.shadow}`}>
                        <div className={style.thead_question_bank}>
                            <div className={style.first}>Question Bank</div>
                            <div className={style.second}>Review</div>
                            <div className={style.third}>Edit</div>
                            <div className={style.fouth}>View</div>
                            <div className={style.last}>Thin File</div>
                        </div>
                        {userDataInfo?.question_bank_management?.map((items, i) => (
                            <div className={style.tbody_question_bank}>
                                <div className={style.first}>{items.name}</div>
                                <div className={style.second}>
                                    <input type="checkbox" checked={items.review} />
                                </div>
                                <div className={style.third}>
                                    <input type="checkbox" checked={items.edit} />
                                </div>
                                <div className={style.fouth}>
                                    <input type="checkbox" checked={items.view} />
                                </div>
                                <div className={style.last}>
                                    <input type="checkbox" checked={items.thin_file} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDetail;
