import React, { useEffect, useRef, useState } from 'react'
import { CallForAuth, CallWithAuth } from '../action/apiActions'
import { GET_QUESTIONBANK, POST_INITIALIZING_QUESTION_CREATION, POST_REFRESH_AUTH } from '../action/apiPath'
import AuthLayout from '../layout/AuthLayout'
import QuestionBank from '../component/molecule/specific/CreateQuestionScreens/QuestionBank'
import { useDispatch, useSelector } from 'react-redux'
import BasicInfo from '../component/molecule/specific/CreateQuestionScreens/BasicInfo/index'
import QuestionCreation from '../component/molecule/specific/CreateQuestionScreens/QuestionCreation/index'
import MediumModal from '../component/atoms/Modal/MediumModal'
import { QuestionCreationScreenBack, QuestionCreationScreenChooseOnlyBack } from '../redux/action'
import SendForReview from '../component/molecule/specific/CreateQuestionScreens/SendForReview'
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu'
import { useLocation } from 'react-router-dom'


import style from '../assets/styles/createQuestion.module.css';

const CreateQuestion = () => {
    // _____________ initialization _____________
    const dispatch = useDispatch()
    // _____________ initialization _____________

    // START : initializeQuestion for getting the ID so that backend will know that we are start creating question 
    const [id, setId] = useState(0)

    const initializeQuestion = async () => {
        const initializeID = await CallWithAuth("POST", POST_INITIALIZING_QUESTION_CREATION)
        if (initializeID.status && typeof initializeID.res.data.id === 'number') {
            setId(initializeID.res.data.id)
        }

    }

    useEffect(() => {
        initializeQuestion()
    }, [])

    // END : initializeQuestion for getting the ID so that backend will know that we are start creating question 



    // _____________ REDUX SELECTOR _____________
    const selector = useSelector((state) => state.QuestionScreenReducer)
    // _____________ REDUX SELECTOR _____________


    // _____________ MODAL OPEN AND CLOSE _____________
    
    const [modalMedium, setModalMedium] = useState(false)
    const [modalData, setModalModalData] = useState({
        "title": "Are You Sure, Do You Want To Change Your Question Type?",
        "description": "Your Current Changes Will Not Be Saved If You Are Changing Your Question Type",
        "cancelButton": "Continue Editing",
        "saveButton": "Yes, Change"
    })

    const openModalMedium = () => {
        setModalMedium(true)
    }

    const closeModalMedium = () => {
        setModalMedium(false)
    }

    // handle back 
    const handleBack = (e) => {
        if (e < selector.screen) {
            dispatch(QuestionCreationScreenChooseOnlyBack(e))
        } else {
            alert("not possible")
        }

    }

    return (
        <>
            <AuthLayout>

                {/* rightpannel top left menu according to different screen  */}
                <RightPanelTopMenu setQuestionBankId={null} />

                {/* CREATE QUESTION SCREEN with name of screen  */}
                <div className={style.create_question}>
                    <div className={style.create_title}>
                        CreateQuestion
                    </div>

                    {selector.screen === 3 ?
                        <div className={style.create_question_screen_list}>

                            {/* select_question_bank  */}
                           
                           
                           
                           
                            <span className={selector.screen === 0 ?  `${style.active} ${style.pointer}` : `${style.pointer}` } > Select question bank &nbsp; </span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.35">
                                    <path d="M3.25758 8.61331C3.29338 8.65007 3.33619 8.67929 3.38346 8.69924C3.43076 8.7192 3.48158 8.72949 3.53292 8.72949C3.58426 8.72949 3.63508 8.7192 3.68238 8.69924C3.72969 8.67928 3.77251 8.65004 3.80832 8.61326L7.04703 5.29017C7.04703 5.29017 7.04704 5.29017 7.04704 5.29016C7.12212 5.21313 7.16414 5.10982 7.16414 5.00224C7.16414 4.89467 7.12212 4.79136 7.04704 4.71432C7.04704 4.71432 7.04703 4.71432 7.04703 4.71432L3.80832 1.39123L3.32917 8.54349M3.25758 8.61331C3.25756 8.61329 3.25754 8.61327 3.25753 8.61326L3.32917 8.54349M3.25758 8.61331C3.2576 8.61333 3.25762 8.61335 3.25764 8.61337L3.32917 8.54349M3.25758 8.61331C3.18485 8.53883 3.14414 8.43885 3.14414 8.33474C3.14414 8.23061 3.18488 8.13061 3.25764 8.05612M3.32917 8.54349C3.27466 8.48768 3.24414 8.41276 3.24414 8.33474C3.24414 8.25673 3.27466 8.18181 3.32917 8.12599M3.25764 8.05612L3.25757 8.05618L3.32917 8.12599M3.25764 8.05612L6.23514 5.00224M3.25764 8.05612L3.32917 8.12599M3.32917 8.12599L6.23514 5.00224M6.23514 5.00224L3.25764 1.94774C3.25763 1.94773 3.25762 1.94772 3.25761 1.94772L3.25757 1.94767L3.32917 1.87787L6.23514 5.00224ZM3.25764 1.39049C3.18488 1.46499 3.14414 1.56499 3.14414 1.66912L3.80829 1.3912C3.77248 1.35443 3.72967 1.32521 3.68238 1.30525C3.63508 1.28529 3.58426 1.275 3.53292 1.275C3.48158 1.275 3.43076 1.28529 3.38346 1.30525C3.33704 1.32484 3.29398 1.35317 3.25753 1.39061L3.32917 1.46037L3.25764 1.39049Z" fill="#8E9EAB" stroke="#272727" stroke-width="0.2" />
                                </g>
                            </svg>

                            {/* basic_info  */}
                            <span className={selector.screen === 1 ?  `${style.active} ${style.pointer}` : `${style.pointer}` }  > &nbsp; Basic Information &nbsp; </span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.35">
                                    <path d="M3.25758 8.61331C3.29338 8.65007 3.33619 8.67929 3.38346 8.69924C3.43076 8.7192 3.48158 8.72949 3.53292 8.72949C3.58426 8.72949 3.63508 8.7192 3.68238 8.69924C3.72969 8.67928 3.77251 8.65004 3.80832 8.61326L7.04703 5.29017C7.04703 5.29017 7.04704 5.29017 7.04704 5.29016C7.12212 5.21313 7.16414 5.10982 7.16414 5.00224C7.16414 4.89467 7.12212 4.79136 7.04704 4.71432C7.04704 4.71432 7.04703 4.71432 7.04703 4.71432L3.80832 1.39123L3.32917 8.54349M3.25758 8.61331C3.25756 8.61329 3.25754 8.61327 3.25753 8.61326L3.32917 8.54349M3.25758 8.61331C3.2576 8.61333 3.25762 8.61335 3.25764 8.61337L3.32917 8.54349M3.25758 8.61331C3.18485 8.53883 3.14414 8.43885 3.14414 8.33474C3.14414 8.23061 3.18488 8.13061 3.25764 8.05612M3.32917 8.54349C3.27466 8.48768 3.24414 8.41276 3.24414 8.33474C3.24414 8.25673 3.27466 8.18181 3.32917 8.12599M3.25764 8.05612L3.25757 8.05618L3.32917 8.12599M3.25764 8.05612L6.23514 5.00224M3.25764 8.05612L3.32917 8.12599M3.32917 8.12599L6.23514 5.00224M6.23514 5.00224L3.25764 1.94774C3.25763 1.94773 3.25762 1.94772 3.25761 1.94772L3.25757 1.94767L3.32917 1.87787L6.23514 5.00224ZM3.25764 1.39049C3.18488 1.46499 3.14414 1.56499 3.14414 1.66912L3.80829 1.3912C3.77248 1.35443 3.72967 1.32521 3.68238 1.30525C3.63508 1.28529 3.58426 1.275 3.53292 1.275C3.48158 1.275 3.43076 1.28529 3.38346 1.30525C3.33704 1.32484 3.29398 1.35317 3.25753 1.39061L3.32917 1.46037L3.25764 1.39049Z" fill="#8E9EAB" stroke="#272727" stroke-width="0.2" />
                                </g>
                            </svg>

                            {/* question_creation   */}
                            <span className={selector.screen === 2.1 || selector.screen === 2.2 ?  `${style.active} ${style.pointer}` : `${style.pointer}` }> &nbsp; Question Creation &nbsp; </span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.35">
                                    <path d="M3.25758 8.61331C3.29338 8.65007 3.33619 8.67929 3.38346 8.69924C3.43076 8.7192 3.48158 8.72949 3.53292 8.72949C3.58426 8.72949 3.63508 8.7192 3.68238 8.69924C3.72969 8.67928 3.77251 8.65004 3.80832 8.61326L7.04703 5.29017C7.04703 5.29017 7.04704 5.29017 7.04704 5.29016C7.12212 5.21313 7.16414 5.10982 7.16414 5.00224C7.16414 4.89467 7.12212 4.79136 7.04704 4.71432C7.04704 4.71432 7.04703 4.71432 7.04703 4.71432L3.80832 1.39123L3.32917 8.54349M3.25758 8.61331C3.25756 8.61329 3.25754 8.61327 3.25753 8.61326L3.32917 8.54349M3.25758 8.61331C3.2576 8.61333 3.25762 8.61335 3.25764 8.61337L3.32917 8.54349M3.25758 8.61331C3.18485 8.53883 3.14414 8.43885 3.14414 8.33474C3.14414 8.23061 3.18488 8.13061 3.25764 8.05612M3.32917 8.54349C3.27466 8.48768 3.24414 8.41276 3.24414 8.33474C3.24414 8.25673 3.27466 8.18181 3.32917 8.12599M3.25764 8.05612L3.25757 8.05618L3.32917 8.12599M3.25764 8.05612L6.23514 5.00224M3.25764 8.05612L3.32917 8.12599M3.32917 8.12599L6.23514 5.00224M6.23514 5.00224L3.25764 1.94774C3.25763 1.94773 3.25762 1.94772 3.25761 1.94772L3.25757 1.94767L3.32917 1.87787L6.23514 5.00224ZM3.25764 1.39049C3.18488 1.46499 3.14414 1.56499 3.14414 1.66912L3.80829 1.3912C3.77248 1.35443 3.72967 1.32521 3.68238 1.30525C3.63508 1.28529 3.58426 1.275 3.53292 1.275C3.48158 1.275 3.43076 1.28529 3.38346 1.30525C3.33704 1.32484 3.29398 1.35317 3.25753 1.39061L3.32917 1.46037L3.25764 1.39049Z" fill="#8E9EAB" stroke="#272727" stroke-width="0.2" />
                                </g>
                            </svg>

                            {/* send_for_review */}
                            <span className={selector.screen === 3 ?  `${style.active} ${style.pointer}` : `${style.pointer}` }> &nbsp; Send For Review  </span>

                        </div> :
                        <div className={style.create_question_screen_list}>

                            {/* select_question_bank  */}
                            <span onClick={() => handleBack(0)} className={selector.screen === 0 ?  `${style.active} ${style.pointer}` : `${style.pointer}` } > Select question bank &nbsp; </span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.35">
                                    <path d="M3.25758 8.61331C3.29338 8.65007 3.33619 8.67929 3.38346 8.69924C3.43076 8.7192 3.48158 8.72949 3.53292 8.72949C3.58426 8.72949 3.63508 8.7192 3.68238 8.69924C3.72969 8.67928 3.77251 8.65004 3.80832 8.61326L7.04703 5.29017C7.04703 5.29017 7.04704 5.29017 7.04704 5.29016C7.12212 5.21313 7.16414 5.10982 7.16414 5.00224C7.16414 4.89467 7.12212 4.79136 7.04704 4.71432C7.04704 4.71432 7.04703 4.71432 7.04703 4.71432L3.80832 1.39123L3.32917 8.54349M3.25758 8.61331C3.25756 8.61329 3.25754 8.61327 3.25753 8.61326L3.32917 8.54349M3.25758 8.61331C3.2576 8.61333 3.25762 8.61335 3.25764 8.61337L3.32917 8.54349M3.25758 8.61331C3.18485 8.53883 3.14414 8.43885 3.14414 8.33474C3.14414 8.23061 3.18488 8.13061 3.25764 8.05612M3.32917 8.54349C3.27466 8.48768 3.24414 8.41276 3.24414 8.33474C3.24414 8.25673 3.27466 8.18181 3.32917 8.12599M3.25764 8.05612L3.25757 8.05618L3.32917 8.12599M3.25764 8.05612L6.23514 5.00224M3.25764 8.05612L3.32917 8.12599M3.32917 8.12599L6.23514 5.00224M6.23514 5.00224L3.25764 1.94774C3.25763 1.94773 3.25762 1.94772 3.25761 1.94772L3.25757 1.94767L3.32917 1.87787L6.23514 5.00224ZM3.25764 1.39049C3.18488 1.46499 3.14414 1.56499 3.14414 1.66912L3.80829 1.3912C3.77248 1.35443 3.72967 1.32521 3.68238 1.30525C3.63508 1.28529 3.58426 1.275 3.53292 1.275C3.48158 1.275 3.43076 1.28529 3.38346 1.30525C3.33704 1.32484 3.29398 1.35317 3.25753 1.39061L3.32917 1.46037L3.25764 1.39049Z" fill="#8E9EAB" stroke="#272727" stroke-width="0.2" />
                                </g>
                            </svg>

                            {/* basic_info  */}
                            <span onClick={() => handleBack(1)} className={selector.screen === 1 ?  `${style.active} ${style.pointer}` : `${style.pointer}` } > &nbsp; Basic Information &nbsp; </span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.35">
                                    <path d="M3.25758 8.61331C3.29338 8.65007 3.33619 8.67929 3.38346 8.69924C3.43076 8.7192 3.48158 8.72949 3.53292 8.72949C3.58426 8.72949 3.63508 8.7192 3.68238 8.69924C3.72969 8.67928 3.77251 8.65004 3.80832 8.61326L7.04703 5.29017C7.04703 5.29017 7.04704 5.29017 7.04704 5.29016C7.12212 5.21313 7.16414 5.10982 7.16414 5.00224C7.16414 4.89467 7.12212 4.79136 7.04704 4.71432C7.04704 4.71432 7.04703 4.71432 7.04703 4.71432L3.80832 1.39123L3.32917 8.54349M3.25758 8.61331C3.25756 8.61329 3.25754 8.61327 3.25753 8.61326L3.32917 8.54349M3.25758 8.61331C3.2576 8.61333 3.25762 8.61335 3.25764 8.61337L3.32917 8.54349M3.25758 8.61331C3.18485 8.53883 3.14414 8.43885 3.14414 8.33474C3.14414 8.23061 3.18488 8.13061 3.25764 8.05612M3.32917 8.54349C3.27466 8.48768 3.24414 8.41276 3.24414 8.33474C3.24414 8.25673 3.27466 8.18181 3.32917 8.12599M3.25764 8.05612L3.25757 8.05618L3.32917 8.12599M3.25764 8.05612L6.23514 5.00224M3.25764 8.05612L3.32917 8.12599M3.32917 8.12599L6.23514 5.00224M6.23514 5.00224L3.25764 1.94774C3.25763 1.94773 3.25762 1.94772 3.25761 1.94772L3.25757 1.94767L3.32917 1.87787L6.23514 5.00224ZM3.25764 1.39049C3.18488 1.46499 3.14414 1.56499 3.14414 1.66912L3.80829 1.3912C3.77248 1.35443 3.72967 1.32521 3.68238 1.30525C3.63508 1.28529 3.58426 1.275 3.53292 1.275C3.48158 1.275 3.43076 1.28529 3.38346 1.30525C3.33704 1.32484 3.29398 1.35317 3.25753 1.39061L3.32917 1.46037L3.25764 1.39049Z" fill="#8E9EAB" stroke="#272727" stroke-width="0.2" />
                                </g>
                            </svg>

                            {/* question_creation   */}
                            <span onClick={() => handleBack(2.1)} className={selector.screen === 2.1 || selector.screen === 2.2 ?  `${style.active} ${style.pointer}` : `${style.pointer}` }> &nbsp; Question Creation &nbsp; </span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.35">
                                    <path d="M3.25758 8.61331C3.29338 8.65007 3.33619 8.67929 3.38346 8.69924C3.43076 8.7192 3.48158 8.72949 3.53292 8.72949C3.58426 8.72949 3.63508 8.7192 3.68238 8.69924C3.72969 8.67928 3.77251 8.65004 3.80832 8.61326L7.04703 5.29017C7.04703 5.29017 7.04704 5.29017 7.04704 5.29016C7.12212 5.21313 7.16414 5.10982 7.16414 5.00224C7.16414 4.89467 7.12212 4.79136 7.04704 4.71432C7.04704 4.71432 7.04703 4.71432 7.04703 4.71432L3.80832 1.39123L3.32917 8.54349M3.25758 8.61331C3.25756 8.61329 3.25754 8.61327 3.25753 8.61326L3.32917 8.54349M3.25758 8.61331C3.2576 8.61333 3.25762 8.61335 3.25764 8.61337L3.32917 8.54349M3.25758 8.61331C3.18485 8.53883 3.14414 8.43885 3.14414 8.33474C3.14414 8.23061 3.18488 8.13061 3.25764 8.05612M3.32917 8.54349C3.27466 8.48768 3.24414 8.41276 3.24414 8.33474C3.24414 8.25673 3.27466 8.18181 3.32917 8.12599M3.25764 8.05612L3.25757 8.05618L3.32917 8.12599M3.25764 8.05612L6.23514 5.00224M3.25764 8.05612L3.32917 8.12599M3.32917 8.12599L6.23514 5.00224M6.23514 5.00224L3.25764 1.94774C3.25763 1.94773 3.25762 1.94772 3.25761 1.94772L3.25757 1.94767L3.32917 1.87787L6.23514 5.00224ZM3.25764 1.39049C3.18488 1.46499 3.14414 1.56499 3.14414 1.66912L3.80829 1.3912C3.77248 1.35443 3.72967 1.32521 3.68238 1.30525C3.63508 1.28529 3.58426 1.275 3.53292 1.275C3.48158 1.275 3.43076 1.28529 3.38346 1.30525C3.33704 1.32484 3.29398 1.35317 3.25753 1.39061L3.32917 1.46037L3.25764 1.39049Z" fill="#8E9EAB" stroke="#272727" stroke-width="0.2" />
                                </g>
                            </svg>

                            {/* send_for_review */}
                            <span className={selector.screen === 3 ?  `${style.active} ${style.pointer}` : `${style.pointer}` }> &nbsp; Send For Review  </span>

                        </div>}
                </div>

                {/* QUESTION_CREATION : DIV  */}
                <div className={style.question_ID}>
                    <div className= {style.title}>
                        {/* START : question ID div  */}
                        <span>
                            {selector.screen === 2.2 ?
                                <button className={` ${style.back_button} ${style.checked_full} ${style.pointer}`} onClick={() => openModalMedium()}>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.67049 15.7954C8.23116 16.2348 7.51884 16.2348 7.0795 15.7954L0.704501 9.42049C0.265166 8.98116 0.265166 8.26884 0.704501 7.8295L7.0795 1.4545C7.51884 1.01517 8.23116 1.01517 8.67049 1.4545C9.10983 1.89384 9.10983 2.60616 8.67049 3.04549L4.21599 7.5H15.375C15.9963 7.5 16.5 8.00368 16.5 8.625C16.5 9.24631 15.9963 9.75 15.375 9.75H4.21599L8.67049 14.2045C9.10983 14.6439 9.10983 15.3561 8.67049 15.7954Z" fill="#00ABFB" stroke="#764FDB" stroke-width="0.5" stroke-linecap="round" />
                                    </svg>
                                </button> : ""}
                            Question ID
                            <b> &nbsp; {id}</b>
                        </span>
                        {/* END : question ID div  */}
                    </div>

                    {/* START : SCREEN - CREATE QUESTION  */}
                    {selector.screen === 0 ? <QuestionBank id={id} /> : ""}
                    {selector.screen === 1 ? <BasicInfo id={id} Draft={0} /> : ""}
                    {selector.screen === 2.1 || selector.screen === 2.2 ? <QuestionCreation id={id} /> : ""}
                    {selector.screen === 3 ? <SendForReview id={id} /> : ""}
                    {/* END : SCREEN - CREATE QUESTION  */}
                </div>
                {/* QUESTION_CREATION : DIV  */}

                {/* MEDIUM MODAL CALL with dispatch function  */}
                <MediumModal modalMedium={modalMedium} forwardButton={() => dispatch(QuestionCreationScreenBack())} openModalMedium={openModalMedium} closeModalMedium={closeModalMedium} modalData={modalData} />

            </AuthLayout>
        </>
    )
}

export default CreateQuestion