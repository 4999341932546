import React, { useEffect, useRef, useState } from 'react'
import { CallWithAuth } from '../../../../../action/apiActions'
import { GET_SUBJECT_TAG } from '../../../../../action/apiPath'
import style from '../../../../../assets/styles/createQuestion.module.css'

const PrimaryTag = ({ selectedPrimaryTag, setSelectedPrimaryTag, primary_id, questionbutton,RemovePrimaryTag }) => {

    // _________ STATE INITIALIZATION _________
    const [subjectTag, setSubjectTag] = useState([])
    const [selectedPrimaryTagStatus, setSelectedPrimaryTagStatus] = useState(false)
    const [subjectTagStatus, setSubjectTagStatus] = useState(false)


    // CALL GET API FOR getting TAGS 
    const TagSubject = async (e) => {

        if (e.length > 2) {
            const data = await CallWithAuth("GET", GET_SUBJECT_TAG + "?keyword=" + e)
            // console.log(data)
            if (data.status && data.res.status === 200) {
                setSubjectTag(data.res.data)
                if (data.res.data.length > 0) {
                    setSubjectTagStatus(true)
                } else {
                    setSubjectTagStatus(false)
                }
            }
        } else {
            setSubjectTagStatus(false)
        }
    }

    const TagAllSubject = async (e) => {
        const data = await CallWithAuth("GET", GET_SUBJECT_TAG)
        // console.log(data)
        if (data.status && data.res.status === 200) {
            setSubjectTag(data.res.data)
        }
    }

    // select tags from options 
    const selectPrimaryTag = (e) => {
        setSelectedPrimaryTag(e)
        setSelectedPrimaryTagStatus(true)
        setSubjectTagStatus(false)
    }

    useEffect(() => {
        // console.log(selectedPrimaryTag)
        if (selectedPrimaryTag === null || selectedPrimaryTag === undefined) {
            setSelectedPrimaryTagStatus(false)
        } else {
            setSelectedPrimaryTagStatus(true)
        }
    }, [selectedPrimaryTag])

    useEffect(() => {
        if (primary_id == null) {
            setSelectedPrimaryTag(null)
        } else {
            // console.log("subjectTag",subjectTag,primary_id)
            const primaryID = subjectTag.filter((item, i) => item.id == primary_id).map((items, i) => items)
            setSelectedPrimaryTag(primaryID[0])
        }
    }, [primary_id, questionbutton, subjectTag])





    const rowRef = useRef();

    const handleRowClick = (event) => {
        if (rowRef.current && !rowRef.current.contains(event.target)) {
            // Check if the click is within the action buttons
            const isActionButtonClick = event.target.closest('.action_buttons');

            if (!isActionButtonClick) {
                setSubjectTagStatus(false)
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleRowClick);

        return () => {
            document.removeEventListener('mousedown', handleRowClick);
        };
    }, [handleRowClick]);


    // console.log("primary_tag", primary_tag)
    useEffect(() => {
        TagAllSubject()
    }, [questionbutton])


    const closePrimary=()=>{
        setSelectedPrimaryTagStatus(false)
        RemovePrimaryTag()
    }

    // console.log("subjectTag",selectedPrimaryTag)

    return (
        < >
            <div className={style.primary_tag}>
                <div className={style.tag}>

                    <div className={style.tag_title}>
                        Primary Tag <b className={style.red_mendantory}>*</b>
                    </div>

                    <div className={style.tag_desc}>
                        Subject | Area | Topic | Subtopic
                    </div>

                    <div className={style.tag_search}>
                        <div className={style.tag_icon}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z" stroke="#212529" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18.2598 19.2592L16.1406 16.8594" stroke="#212529" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span>Tags</span>
                        </div>

                        <div className={style.input_search} ref={rowRef}>
                            {selectedPrimaryTagStatus ? <span>{selectedPrimaryTag?.subject} |  {selectedPrimaryTag?.area} | {selectedPrimaryTag?.topic} | {selectedPrimaryTag?.subtopic} </span> :
                                <input type='search' id='searchInput' placeholder='' onChange={(e) => TagSubject(e.target.value)} />}

                            {subjectTagStatus ?
                                <div className={style.list_input_primary}>
                                    {subjectTag?.map((items, i) => (
                                        <>
                                            <div className={style.pointer} onClick={() => selectPrimaryTag(items)}><span>{items.subject} | {items.area} | {items.topic} | {items.subtopic}  </span></div>
                                        </>
                                    ))}
                                </div>
                                : ""}

                        </div>

                        <div className={style.cancel_button}>
                            <button className={style.pointer} onClick={() => closePrimary()}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.75781 7.75781L16.2431 16.2431" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.75691 16.2431L16.2422 7.75781" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </ >
    )
}

export default PrimaryTag