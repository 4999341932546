import React, { useEffect, useState } from 'react'
import SideBar from '../component/molecule/common/SideBar'
import RightPanel from '../component/molecule/common/RightPanel'
import { CallForAuth, CallWithAuth } from '../action/apiActions'
import { GET_AUTHENTICATION, POST_REFRESH_AUTH } from '../action/apiPath'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

const AuthLayout = ({ children, pageName }) => {

    // ________ initialization :start ____________
  const navigate = useNavigate();
    // ________ initialization :end ____________

  const [rightbarshow, setRightBarshow] = useState(false)
  const [rightbarSearchshow, setRightBarSearchshow] = useState(false)
  const [menu, setmenu] = useState(false)
  const [sidebarshow, setSideBarshow] = useState(false)


  // ________ REMOVE SIDEBAR ________
  const RemoveSideBar = () => {
    setRightBarshow(!rightbarshow)
    setSideBarshow(!sidebarshow)
    setmenu(!menu)
  }



 //  call refresh API to get JWT and store in cookies 
  const CheckAuthwithRefresh = async () => {
    const refreshToken = Cookies.get('refreshToken').split(',')[0]
    // console.log("refreshToken", `jwt ${refreshToken}`)

    const checkAuthRefresh = await CallForAuth(`jwt ${refreshToken}`, "POST", POST_REFRESH_AUTH)
    // console.log(checkAuthRefresh)
    if (checkAuthRefresh.status && checkAuthRefresh.res.data.jwt != null) {
      Cookies.set('accessToken', checkAuthRefresh.res.data.jwt)
    } else {
      navigate("/");
    }

  }


 
  //  check that user is authenticated with(  ME API ) or not-> if not the call refresh API 
  const CheckAuthwithoutRefresh = async () => {
    const checkAuth = await CallWithAuth("GET", GET_AUTHENTICATION)
    // console.log(checkAuth)
    if (!checkAuth.status && checkAuth.res.status === 401) {
      CheckAuthwithRefresh()
    }
  }

  useEffect(() => {
    CheckAuthwithoutRefresh()
  }, [])


  return (
    <>
      <section id="dashboard">
        <SideBar sidebarshow={sidebarshow} setSideBarshow={setSideBarshow} />
        {/* passing all pages as children inside right panel of dashboard */}
        <RightPanel rightbarshow={rightbarshow} setRightBarshow={setRightBarshow} rightbarSearchshow={rightbarSearchshow} setRightBarSearchshow={setRightBarSearchshow} RemoveSideBar={RemoveSideBar} menu={menu} sidebarshow={sidebarshow} setSideBarshow={setSideBarshow}>
          {children}
        </RightPanel>
      </section>
    </>
  )
}

export default AuthLayout