import React, { useEffect, useState } from "react";
import style from "../../../../assets/styles/userManagement.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAddUser,
  GetRoles,
  GetOraganisationList,
  getUserQuestionBank,
  UserScreenChange,
} from "../../../../redux/action";

const AddUser = () => {
  // _________ GET QUESTION BANK API CALL SAVED THE DATA IN STATE _________
  const dispatch = useDispatch();
  const { questionBank } = useSelector((state) => state.GetAllData);

  useEffect(() => {
    dispatch(getUserQuestionBank());
  }, []);

  //__________________  ADD USERS ___________________
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [userOrganisation, setUserOrganisation] = useState(null);
  const [userQuestionBank, setUserQuestionBank] = useState([]);
  const [userIs_active, setUserIs_active] = useState(false);

  const QuestionBankAdd = (e, selection, event) => {
    if (event.target.checked) {
      if (userQuestionBank.some((items, i) => items.id === e.id)) {
        const foundItem = userQuestionBank.find((item) => item.id === e.id);
        if (foundItem) {
          foundItem[selection] = true;
        }
        setUserQuestionBank(userQuestionBank);
      } else {
        setUserQuestionBank((current) => [
          ...current,
          {
            name: e.name,
            id: e.id,
            question_bank: 1,
            review: selection === "review" ? true : false,
            edit: selection === "edit" ? true : false,
            view: selection === "view" ? true : false,
            thin_file: selection === "thin_file" ? true : false,
          },
        ]);
      }
    } else {
      const indexToRemove = userQuestionBank.findIndex(
        (item) => item.id === e.id
      );
      if (indexToRemove !== -1) {
        userQuestionBank.splice(indexToRemove, 1);
      }
    }
  };

  // _______________ roles get ______________

  // console.log("userName", userName);

  const { roles } = useSelector((state) => state.GetAllData);

  useEffect(() => {
    dispatch(GetRoles());
  }, []);

  //_______  ADD ROLES ______
  const AddRoles = (e) => {
    setUserRoles((current) => [...current, Number(e)]);
  };

  // ________ REMOVE ROLES ________
  const RomeveUserRoles = (e) => {
    const filteredUserroles = userRoles.filter((id) => id !== e.id);
    setUserRoles(filteredUserroles);
  };

  // ________ oganisation __________
  const { organisationList } = useSelector((state) => state.GetAllData);

  useEffect(() => {
    dispatch(GetOraganisationList());
  }, []);

  // ______________ BODY OF ADD USERS ____________
  var body = {
    name: userName,
    email_id: userEmail,
    mobile_number: userPhone,
    roles: userRoles,
    organization: userOrganisation,
    is_active: userIs_active,
    question_bank_management: userQuestionBank,
  };

  // console.log("userRoles", userRoles);

  // ______________ BODY OF ADD USERS ____________
  var body = {
    name: userName,
    email_id: userEmail,
    mobile_number: userPhone,
    roles: userRoles,
    organization: userOrganisation,
    is_active: userIs_active,
    question_bank_management: userQuestionBank,
  };


  return (
    <div>
      <div className={style.top_section}>
        <span
          className={style.left}
          onClick={() => dispatch(UserScreenChange(0))}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5488 19.422L9.02883 12.902C8.25883 12.132 8.25883 10.872 9.02883 10.102L15.5488 3.58203"
              stroke="#212529"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Back
        </span>
      </div>

      <div className={style.table_content_report}>
        <div
          className={` ${style.top_content_table} ${style.profile_title_div}`}
        >
          User Profile
        </div>

        <div className={style.user_profile}>
          <div className={style.tags}>
            <div className={style.subject}>
              <p>Full Name </p>
              <input
                className={style.input_search}
                type="search"
                id="searchInput"
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Enter your full name"
              />
            </div>
            <div className={style.subject}>
              <p>Email ID </p>
              <input
                type="search"
                className={style.input_search}
                id="searchInput"
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="Enter your Email ID"
              />
            </div>
          </div>
        </div>

        <div className={style.user_profile}>
          <div className={style.tags}>
            <div className={style.subject}>
              <p>Mobile Number </p>
              <input
                className={style.input_search}
                type="search"
                id="searchInput"
                onChange={(e) => setUserPhone(e.target.value)}
                placeholder="Enter your mobile number"
              />
            </div>
            <div className={style.subject}>
              <p>Roles </p>
              <select
                className={style.dropdown_css}
                id="searchInput"
                placeholder="Select your roles"
                name="cars"
                onChange={(e) => AddRoles(e.target.value)}
              >
                <option hidden>Select Roles</option>
                {roles
                  ?.filter((item) => !userRoles.includes(item.id))
                  ?.map((items, i) => (
                    <option value={items.id}>{items.name}</option>
                  ))}
              </select>
              {roles
                ?.filter((item) => userRoles.includes(item.id))
                .map((items, i) => (
                  <span className={style.roleBox}>
                    {items.name}
                    <span onClick={() => RomeveUserRoles(items)}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.34375 6.34375L17.6575 17.6575"
                          stroke="#272727"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.34254 17.6575L17.6562 6.34375"
                          stroke="#272727"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </span>
                ))}
            </div>
          </div>
        </div>

        <div className={style.user_profile}>
          <div className={style.tags}>
            <div className={style.subject}>
              <p>Organisation </p>
              <select
                className={style.dropdown_css}
                id="searchInput"
                placeholder="Select your roles"
                name="cars"
                onChange={(e) => setUserOrganisation(Number(e.target.value))}
              >
                <option value="0" hidden>
                  Select organisation
                </option>
                {organisationList?.map((items, i) => (
                  <option value={items.id}>{items.name}</option>
                ))}
              </select>
              {organisationList
                ?.filter((item) => item.id == userOrganisation)
                .map((items, i) => (
                  <span className={style.roleBox}>{items.name}</span>
                ))}
            </div>
          </div>
        </div>

        <div className={` ${style.select_question} ${style.profile_status}`}>
          <div className={style.info}>
            {/* format  */}
            <div className={style.format}>
              <div className={style.format_title}>
                Status 
              </div>
              <div className={style.format_choice}>
                <div>
                  <input
                    type="radio"
                    className={style.pointer}
                    id="question"
                    onClick={() => setUserIs_active(true)}
                    name="choose_question"
                    value="Question"
                  />
                  <label for="html">Active</label>
                </div>
                <div>
                  <input
                    type="radio"
                    className={style.pointer}
                    id="question"
                    onClick={() => setUserIs_active(false)}
                    name="choose_question"
                    value="Question"
                  />
                  <label for="html">Inactive</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={` ${style.select_question} ${style.profile_status}`}>
          <div className={style.title}>Question Bank management</div>

          <div className={style.question_bank_table}>
            <div className={style.thead_question_bank}>
              <div className={style.first}>Question Bank</div>
              <div className={style.second}>Review</div>
              <div className={style.third}>Edit</div>
              <div className={style.fouth}>View</div>
              <div className={style.last}>Thin File</div>
            </div>
            {questionBank?.map((items, i) => (
              <div className={style.tbody_question_bank}>
                <div className={style.first}>{items.name}</div>
                <div className={style.second}>
                  <input
                    type="checkbox"
                    onClick={(e) => QuestionBankAdd(items, "review", e)}
                  />
                </div>
                <div className={style.third}>
                  <input
                    type="checkbox"
                    onClick={(e) => QuestionBankAdd(items, "edit", e)}
                  />
                </div>
                <div className={style.fouth}>
                  <input
                    type="checkbox"
                    onClick={(e) => QuestionBankAdd(items, "view", e)}
                  />
                </div>
                <div className={style.last}>
                  <input
                    type="checkbox"
                    onClick={(e) => QuestionBankAdd(items, "thin_file", e)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        className={` ${style.saveButton_questionBank} ${style.margin_top_20}`}
      >
        <div
          className={` ${style.cancel} ${style.pointer}`}
          onClick={() => dispatch(UserScreenChange(0))}
        >
          Cancel
        </div>
        <div>
          <button className={style.blue_button} onClick={() => dispatch(GetAddUser(body))}>
            Add User
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
