
import React, { useEffect, useState } from 'react'
import { GET_QUESTIOB_TYPE } from '../../../../../action/apiPath'
import { CallWithAuth } from '../../../../../action/apiActions'
import { QuestionCreationScreen, QuestionCreationScreenCancel } from '../../../../../redux/action'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import MediumModal from '../../../../atoms/Modal/MediumModal'

import style from '../../../../../assets/styles/createQuestion.module.css';


const SelectType = ({ id, data, format, handleQuestionType, index, selectedID, getData }) => {
    // ________ initialize ________
    const location = useLocation();
    const receivedState = location.state;
    // console.log("receivedState", receivedState)

    const dispatch = useDispatch()
    const navigate = useNavigate();
    // ________ ACCORDIAN OPENER ___________
    const [open, setOpen] = useState(true)

    const openAccordian = () => {
        setOpen(true)
    }
    const closeAccordian = () => {
        setOpen(false)
    }



    //  ________ SELECT CATEGORY ________
    // const [select, setSelect] = useState(null)


    // CHOOSECATEGORY 
    const SelectCategoryKey = (i) => {
        setSelectedQuestionCategory(i)
        // setSelect(null)
        setQuestiontypeId(null)
    }
    // CHOOSECATEGORY_ID 
    const [QuestionTypeId, setQuestiontypeId] = useState(null)
    const SelectCategoryId = (key, data) => {
        // console.log(key,data)
        // setSelect(key)
        setQuestiontypeId(data)
    }


    useEffect(() => {
        // // console.log("data",data.questiontype.question_type_id)
        setQuestiontypeId(data?.questiontype?.question_type_id)
    }, [data])



    // ________ get category from API and grouped in sequence ________
    const [questionCategory, setQuestionCategory] = useState([])
    const [slectedquestionCategory, setSelectedQuestionCategory] = useState(0)

    const GetQuestionTypeCategory = async () => {
        const category = await CallWithAuth("GET", GET_QUESTIOB_TYPE)
        if (category.status && category.res.status === 200) {

            const groupedData = {};

            category?.res?.data.forEach(item => {
                const category = item.category;
                if (!groupedData[category]) {
                    groupedData[category] = [];
                }
                groupedData[category].push(item);
            });

            // console.log("groupedData", groupedData["Data Analysis"]);
            setQuestionCategory(groupedData)

            // const onlyCategory = category?.res?.data?.map(items => items.category)
            // setQuestionCategory(Array.from(new Set(onlyCategory)))
        }
    }

    useEffect(() => {
        GetQuestionTypeCategory()
    }, [])


    // SELECT QuestionTypeId SAVED to make status false
    const [saveButtonStatus, setSaveButtonStatus] = useState(true)

    useEffect(() => {
        if (QuestionTypeId === null) {
            setSaveButtonStatus(true)
        } else {
            if (QuestionTypeId?.id === null) {
                setSaveButtonStatus(true)
            } else {
                setSaveButtonStatus(false)
            }

        }

        // if (QuestionTypeId?.id == 1 || QuestionTypeId?.id == 2) {
        //     setSelectedQuestionCategory(0)
        // } else {
        //     setSelectedQuestionCategory(1)
        // }
    }, [QuestionTypeId])

    // ________ body of the selection type  ________
    const body = {
        "question_type_id": QuestionTypeId
    }

    // console.log("QuestionTypeId", QuestionTypeId, slectedquestionCategory)


    // _____________ MODAL OPEN AND CLOSE _____________
    const [modalMedium, setModalMedium] = useState(false)
    const [modalData, setModalModalData] = useState({
        "title": "Are You Sure, Do You Want To Cancel Question Creation?",
        "description": "Your Current Changes Will Not Be Saved And You Will Be Redirected To Question Listing ",
        "cancelButton": "Continue Editing",
        "saveButton": "Yes, Cancel"
    })

    const openModalMedium = () => {
        setModalMedium(true)
    }

    const closeModalMedium = () => {
        setModalMedium(false)
    }


    // cancel button to send the user to question-bank 
    const Cancel = () => {
        navigate('/question-bank')
        dispatch(QuestionCreationScreenCancel())
    }

    // console.log("format", format == undefined)


    const [hoveredItem, setHoveredItem] = useState(null);

    const handleMouseOver = (item) => {
        setHoveredItem(item);
    };

    const handleMouseOut = () => {
        setHoveredItem(null);
    };



    useEffect(() => {
        if (selectedID != null) {
            if(selectedID==3 || selectedID==4 || selectedID==5){
                setSelectedQuestionCategory(1)
            }
            setQuestiontypeId({ "id": selectedID })
            setHoveredItem(null)
        } else {
            setQuestiontypeId({ "id": null })
        }
    }, [selectedID])


    useEffect(() => {
        if (getData) {
            if(getData?.question_type_id?.id==3 || getData?.question_type_id?.id==4 || getData?.question_type_id?.id==5){
                setSelectedQuestionCategory(1)
            }
            setQuestiontypeId(getData?.question_type_id)
          
        }
    }, [getData])


    // console.log("selectedID", selectedID, saveButtonStatus)



    return (
        <>
            <div className={style.question_type_box} style={{ height: open ? "90%" : "5%" }}>
                {/* ________ top of selection  ________*/}
                <div>
                    <div className={style.question_type_title}>
                        <span>Select Question Type</span>

                        {open ? <svg className={style.pointer} onClick={closeAccordian} width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.07992 16.7617L10.5999 9.96271C11.3699 9.15976 12.6299 9.15976 13.3999 9.96271L19.9199 16.7617" stroke="#212529" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> : <svg className={style.pointer} onClick={openAccordian} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922" stroke="#212529" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        }

                    </div>
                    <div>
                    </div>
                </div>

                <div className={style.category} style={{ display: open ? "flex" : 'none' }}>
                    {/* ________ sub-category by grouping the API  ________*/}
                    <div className={style.sub_category}>
                        {Object.keys(questionCategory)?.map((items, i) => (
                            <div className={slectedquestionCategory === i ? ` ${style.underline_bold} ${style.pointer}` : `${style.pointer}`} onClick={() => SelectCategoryKey(i)}>{items}</div>
                        ))}


                    </div>
                    {/* ________ name-category by grouping the API  ________*/}
                    <div className={style.name_category}>
                        <div className={style.selection_box} key={index}>

                            {Object.values(questionCategory)?.filter((item, key) => key === slectedquestionCategory).map((items, i) => (
                                <>
                                    {items.map((item, key) => (
                                        <div className={` ${style.select_type} ${style.pointer}`} onClick={() => SelectCategoryId(key, item)}>
                                            <p style={{ color: QuestionTypeId?.id === item.id ? '#00ABFB' : "#212529" }}>{item.name}</p>


                                            {hoveredItem == item?.id ?
                                                <div className={style.select_box_type} onMouseOut={() => handleMouseOut()} onClick={() => handleQuestionType(item.id)} style={{ borderColor: QuestionTypeId?.id === item.id ? "#00ABFB" : "#C7CCD1", display: "flex", justifyContent: "center", height: "120px", width: "150px", alignItems: "center", background: "#0000001a" }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="64px" height="64px"><path fill="#4caf50" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" /><path fill="#fff" d="M21,14h6v20h-6V14z" /><path fill="#fff" d="M14,21h20v6H14V21z" /></svg>
                                                </div>
                                                :
                                                <div className={style.select_box_type} onClick={() => handleQuestionType(item.id)} onMouseOver={() => handleMouseOver(item.id)}
                                                    style={{ borderColor: QuestionTypeId?.id === item.id ? "#00ABFB" : "#C7CCD1", height: "120px", width: "150px", alignItems: "center" }}>
                                                    <p>Which colour has the smallest wavelength?</p>
                                                    <div>
                                                        <input type='radio' name='single-answer' id='single-answer' />
                                                        <label>Red</label>
                                                    </div>


                                                    <div className={style.checked_full}>
                                                        <input type='radio' name='single-answer' id='single-answer' checked />
                                                        <label >Violet </label>
                                                    </div>

                                                    <div>
                                                        <input type='radio' name='single-answer' id='single-answer' />
                                                        <label>Green</label>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    ))}
                                </>
                            ))}

                            {/* <div className='select-type' onClick={() => setSelect(1)}>
                                <p style={{ color: select === 1 ? '#00ABFB' : "#212529" }}>Multiple Select Question</p>
                                <div className='select-box-type' style={{ borderColor: select === 1 ? "#00ABFB" : "#C7CCD1" }}>
                                    <p>Which colour has the smallest wavelength?</p>
                                    <div className='checked-full'>
                                        <input type='checkbox' checked name='single-answer' id='single-answer' />
                                        <label>Red</label>
                                    </div>


                                    <div>
                                        <input type='checkbox' name='single-answer' id='single-answer' />
                                        <label>Violet </label>
                                    </div>

                                    <div className='checked-full'>
                                        <input type='checkbox' checked name='single-answer' id='single-answer' />
                                        <label>Green</label>
                                    </div>
                                </div>
                            </div>
                            <div className='select-type' onClick={() => setSelect(2)}>
                                <p style={{ color: select === 2 ? '#00ABFB' : "#212529" }}>Select Answer For Multiple Options</p>
                                <div className='select-box-type' style={{ borderColor: select === 2 ? "#00ABFB" : "#C7CCD1" }}>
                                    <p>Which colour has the smallest wavelength?</p>
                                    <div className='checked-full'>
                                        <input type='checkbox' checked name='single-answer' id='single-answer' />
                                        <label>Red</label>
                                    </div>


                                    <div className='checked-full'>
                                        <input type='checkbox' checked name='single-answer' id='single-answer' />
                                        <label>Violet </label>
                                    </div>

                                    <div className='checked-full'>
                                        <input type='checkbox' checked name='single-answer' id='single-answer' />
                                        <label>Green</label>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        {/* _________ SAVE BUTTON _________ */}
                        {format == 1 ? "" : <div className={style.saveButton_questionCreation}>
                            <div className={` ${style.cancel} ${style.pointer}`} onClick={() => openModalMedium()}>
                                Cancel
                            </div>
                            <div>
                                <button className={saveButtonStatus ? ` ${style.blue_button_disable}` : `${style.blue_button}`} disabled={saveButtonStatus} onClick={() => dispatch(QuestionCreationScreen(body))}>Save & Continue</button>
                            </div>
                        </div>}



                    </div>

                </div>

                <MediumModal modalMedium={modalMedium} forwardButton={() => Cancel()} openModalMedium={openModalMedium} closeModalMedium={closeModalMedium} modalData={modalData} />

            </div>

        </>
    )
}

export default SelectType