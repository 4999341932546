import React, { useEffect, useRef, useState } from 'react'
import { CallForAuth, CallWithAuth } from '../action/apiActions'
import { GET_QUESTION, GET_QUESTIONBANK, GET_QUESTION_DRAFT, POST_INITIALIZING_QUESTION_CREATION, POST_REFRESH_AUTH } from '../action/apiPath'
import AuthLayout from '../layout/AuthLayout'
import QuestionBank from '../component/molecule/specific/CreateQuestionScreens/QuestionBank'
import { useDispatch, useSelector } from 'react-redux'
import BasicInfo from '../component/molecule/specific/CreateQuestionScreens/BasicInfo/index'
import QuestionCreation from '../component/molecule/specific/CreateQuestionScreens/QuestionCreation/index'
import MediumModal from '../component/atoms/Modal/MediumModal'
import { BasicInformation, CreateQuestionAction, QuestionCreationScreen, QuestionCreationScreenBack, QuestionCreationScreenChooseOnlyBack } from '../redux/action'
import SendForReview from '../component/molecule/specific/CreateQuestionScreens/SendForReview'
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu'
import { useLocation } from 'react-router-dom'
import CreatingQuestionSingle from '../component/molecule/specific/CreateQuestionScreens/QuestionCreation/CreatingQuestionSingle'


import style from '../assets/styles/createQuestion.module.css';

const EditQuestion = () => {
    // _____________ initialization _____________
    const dispatch = useDispatch()
    const location = useLocation();
    const receivedState = location.state;
    // console.log("receivedState", receivedState)
    // _____________ initialization _____________

    // START : initializeQuestion for getting the ID so that backend will know that we are start creating question 
    const [id, setId] = useState(0)
    const [getData, setData] = useState(null)
    const [Draft, setDraft]= useState(0)
    const GetQuetsion = async () => {
        let draft = 0;
        if(receivedState.question_draft_id == receivedState.question_id) draft=1
        const data = await CallWithAuth("GET", GET_QUESTION_DRAFT + receivedState.question_draft_id + "/get-details/?draft="+ draft )
        if (data.status && data.res.status == 200 && Array.isArray(data.res.data)) {
            if(data.res.data.length > 1){
                setData(data.res.data)
                setId(data.res.data[0]?.question_draft_id)
                setDraft(draft)
            }else{
                if(draft==1){
                    setData(data.res.data)
                    setId(data.res.data[0]?.question_draft_id)
                    setDraft(draft)
                }else{
                    setData(data.res.data[0])
                    setId(data.res.data[0]?.question_draft_id)
                    setDraft(draft)
                }
               
            }
           
        }
    }

    useEffect(() => {
        GetQuetsion()
    }, [receivedState])
    // END : initializeQuestion for getting the ID so that backend will know that we are start creating question 


    // _____________ REDUX SELECTOR _____________
    const selector = useSelector((state) => state.QuestionScreenReducer)
    // console.log("selector", selector)

    const reduxdata = useSelector((state) => state)
    // console.log("reduxdata", reduxdata)
    // _____________ REDUX SELECTOR _____________




    // _____________ MODAL OPEN AND CLOSE _____________
    const [modalMedium, setModalMedium] = useState(false)
    const [modalData, setModalModalData] = useState({
        "title": "Are You Sure, Do You Want To Change Your Question Type?",
        "description": "Your Current Changes Will Not Be Saved If You Are Changing Your Question Type",
        "cancelButton": "Continue Editing",
        "saveButton": "Yes, Change"
    })

    const openModalMedium = () => {
        setModalMedium(true)
    }

    const closeModalMedium = () => {
        setModalMedium(false)
    }

    // handle back 
    const handleBack = (e) => {
        if (e < selector.screen) {
            dispatch(QuestionCreationScreenChooseOnlyBack(e))
        } else {
            alert("not possible")
        }
    }



    useEffect(() => {
        if (id !== 0) {
            if (selector.screen === 0) {
               if(Array.isArray(getData)){
                dispatch(CreateQuestionAction(getData[0].question_bank, id))
              
               }else{
                dispatch(CreateQuestionAction(getData?.question_bank, id))
               }
            } 
            
            // else if (selector.screen === 1) {
            //     if (getData.format == 0) {
            //         dispatch(BasicInformation({
            //             "format": getData.format,
            //             "difficulty": getData.difficulty,
            //             "category": 2,
            //             "academic_year": getData.academic_year,
            //             "primary_tag_id": getData.primary_tag.id,
            //             "secondary_tags": getData?.secondary_tags?.map(item => item.id),
            //             "free_tags": getData?.free_tags?.map(item => item.id),
            //             "new_free_tags": getData?.new_free_tags?.map(item => item.id)
            //         }, id, 0))
            //     } else if (getData.format == 1) {

            //         const generateBodyForMultiQuestion = () => {
            //             return getData.map((question, index) => ({
            //                 idx: index,
            //                 question_type_id: question?.question_type_id?.id,
            //                 difficulty: question?.difficulty,
            //                 category: 2,
            //                 academic_year: question?.academic_year,
            //                 primary_tag_id: question?.primary_tag?.id,
            //                 secondary_tags: question?.secondary_tags?.map(item => item?.id),
            //                 free_tags: question?.free_tags?.map(item => item?.id),
            //                 new_free_tags: [],
            //             }));
            //         };
            //         dispatch(BasicInformation({
            //             "question_group": generateBodyForMultiQuestion(),
            //             "group_name": "NAME 1",
            //             "format": 1
            //         }, id, 1))
            //     }

            // } else if (selector.screen === 2.1) {
            //     dispatch(QuestionCreationScreen({
            //         "question_type_id": getData.question_type_id
            //     }))
            // }
        }
    }, [id, selector])

    // console.log("getData==",getData)

    return (
        <>
            <AuthLayout>

                {/* rightpannel top left menu according to different screen  */}
                <RightPanelTopMenu setQuestionBankId={null} />

                {/* CREATE QUESTION SCREEN with name of screen  */}
                <div className={style.create_question}>
                    <div className={style.create_title}>
                        Edit Question
                    </div>
                </div>

                {/* QUESTION_CREATION : DIV  */}
                <div className={style.question_ID}>
                    <div className={style.title}>
                        {/* START : question ID div  */}
                        <span>
                            Question ID
                            <b> &nbsp; {id}</b>
                        </span>
                        {/* END : question ID div  */}
                    </div>

                    {/* START : SCREEN - CREATE QUESTION  */}
                    {selector.screen === 0 ? <QuestionBank id={id} /> : ""}
                    {selector.screen === 1 ? <BasicInfo getData={getData} id={id} Draft={Draft}/> : ""}
                    {selector.screen === 2.1 || selector.screen === 2.2 ? <QuestionCreation getData={getData} id={id} /> : ""}
                    {selector.screen === 3 ? <SendForReview id={id} /> : ""}

                    {/* { selector.screen === 2.2 ? <QuestionCreation id={id} /> : ""} */}

                    {/* END : SCREEN - CREATE QUESTION  */}
                </div>
                {/* QUESTION_CREATION : DIV  */}


                {/* MEDIUM MODAL CALL with dispatch function  */}
                <MediumModal modalMedium={modalMedium} forwardButton={() => dispatch(QuestionCreationScreenBack())} openModalMedium={openModalMedium} closeModalMedium={closeModalMedium} modalData={modalData} />

            </AuthLayout>
        </>
    )
}

export default EditQuestion