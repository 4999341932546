import React, { useEffect, useState } from "react";

import style from "../../../../assets/styles/userManagement.module.css";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDetail, GetUserLists, UserScreenChange } from "../../../../redux/action";


const UserList = ({ setScreen }) => {

  const [keyword, setKeyword] = useState(null)
  const [sort, setsort] = useState("")
  const [sortStatus, setsortStatus] = useState(true)
  const [sortOrganisation, setsortOrganisation] = useState(true)

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetUserLists(keyword, sort))
  }, [keyword, sortOrganisation,sortStatus])


  const sortOrganisationFunction =()=>{
    if(sortOrganisation){
      setsort("organization")
      setsortOrganisation(!sortOrganisation)
    }else{
      setsort("")
      setsortOrganisation(!sortOrganisation)
    }
  }

  const sortStatusFunction=()=>{
    if(sortStatus){
      setsort("is_active")
      setsortStatus(!sortStatus)
    }else{
      setsort("")
      setsortStatus(!sortStatus)
    }
  }

  const { userData } = useSelector((state) => state.GetAllData);
  
  return (
    <div>
      <div className={style.question_bank}>
        {/* TABLE SECTION OF THIS PAGE  */}
        <div className={style.table_content_report}>
          {/* _______________________Top content of table_______________________  */}
          <div className={style.top_content_table}>
            {/*_______________________  Search bar  _______________________ */}
            <div
              className={` ${style.search_listing} ${style.user_management_search_listing}`}
            >
              <div className={style.tag_icon}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18Z"
                    stroke="#212529"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.2598 19.2592L16.1406 16.8594"
                    stroke="#212529"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className={style.input_search}>
                <input
                  type="search"
                  id="searchInput"
                  placeholder="Search for subject, area topic, sub topic, question ID tags to refine list"
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div>
            </div>
            {/*_______________________  filter  _______________________ */}
            <div
              className={` ${style.filter}  ${style.user_management_filter}`}
            ></div>
            {/*_______________________  Add Question Button  _______________________ */}
            <div className={style.add_question}>
              <button
                className={` ${style.add_new_question} ${style.pointer}`}
                onClick={() => dispatch(UserScreenChange(1))}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    fill="white"
                  />
                  <path
                    d="M7.5 12H16.5"
                    stroke="#00ABFB"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 16.5V7.5"
                    stroke="#00ABFB"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>Add User</span>
              </button>
            </div>
          </div>

          {/* TABLE LISTING OF USERS  */}
          <div className={style.sub_head_content_table}>
            <table>
              <thead>
                <tr>
                  <th className={` ${style.first} ${style.pointer}`}>
                    <span>User Name</span>
                  </th>
                  <th
                    className={` ${style.first} ${style.pointer}`}
                    onClick={() => sortOrganisationFunction()}
                  >
                    <span>Organisation</span>
                    <svg
                      width="19"
                      height="12"
                      viewBox="0 0 19 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                        fill="#00ABFB"
                      />
                    </svg>
                  </th>
                  <th className={` ${style.second} ${style.pointer}`}>
                    {" "}
                    <span>User Role</span>
                  </th>
                  <th className={` ${style.third} ${style.pointer}`}>
                    <span>Question Banks</span>
                  </th>
                  <th className={` ${style.fourth} ${style.pointer}`}>
                    <span>Subjects</span>
                  </th>
                  <th
                    className={` ${style.fifth} ${style.pointer}`}
                    onClick={() => sortStatusFunction()}
                  >
                    <span>Status</span>
                    <svg
                      width="19"
                      height="12"
                      viewBox="0 0 19 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                        fill="#00ABFB"
                      />
                      <path
                        d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                        fill="#00ABFB"
                      />
                    </svg>
                  </th>
                  <th></th>
                </tr>
              </thead>

              {/* ____________ user Data Listing _____________ */}
              <tbody>
                {userData?.map((item, i) => (
                  <tr key={i} className={style.table_body_content}>
                    <td>
                      <div className={style.user_management_table_body_content}>
                        {item.username}
                      </div>
                    </td>
                    <td>
                      <div className={style.user_management_table_body_content}>
                        {item.organization}
                      </div>
                    </td>
                    <td>
                      <div className={style.user_management_table_body_content}>
                        {" "}
                        {item?.user_roles?.map((key, i) => key.name)}
                      </div>
                    </td>
                    <td>
                      <div className={style.user_management_table_body_content}>
                        {item?.question_banks
                          ?.filter((key, i) => i === 0)
                          .map((key, i) => key)}
                        {item?.question_banks.length > 1 ? (
                          <span>+{item?.question_banks.length - 1}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td>
                      <div className={style.user_management_table_body_content}>
                        Quanitive Apptitude
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          item.is_active
                            ? `${style.activate}`
                            : `${style.inactive}`
                        }
                      >
                        {" "}
                        <span>{item.is_active ? "Active" : "In-Active"}</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className={` ${style.action} ${style.pointer}`}
                        onClick={() => dispatch(GetUserDetail(item.id))}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.62891 3.39766L12.0622 8.83099C12.7039 9.47266 12.7039 10.5227 12.0622 11.1643L6.62891 16.5977"
                            stroke="#212529"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
