import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CallForAuth, CallWithAuth, CallWithOutAuth } from '../action/apiActions';
import { GET_AUTHENTICATION } from '../action/apiPath';


import style from '../assets/styles/createQuestion.module.css';


const Home = () => {

  // ________ initialization :start ____________
  const url = useLocation()
  const navigate = useNavigate();
  // ________ initialization :end ____________



  // Start _______ getting the authtoken from the url and storing it in Cookies 

  const login = async (params) => {
    const token = params.split("=")[1]


    const checkAuth = await CallForAuth(`jwt ${token}`, "GET", GET_AUTHENTICATION)
    // console.log(checkAuth)
    if (checkAuth.status && checkAuth.res.data.jwt != null) {
      navigate("/question-bank");
      Cookies.set('accessToken', checkAuth.res.data.jwt)
      Cookies.set('refreshToken', checkAuth.res.data.jwt_refresh)
      Cookies.set('firstName', checkAuth.res.data.first_name)
      Cookies.set('lastName', checkAuth.res.data.last_name)

    } else {
      Cookies.set('accessToken', null)
    }

  }

  useEffect(() => {
    login(url.search)
  }, [url])
  //_______ getting the authtoken from the url and storing it in Cookies_______ END:


  // drag practice

  const draggables = document.querySelectorAll('.draggable')
  const containers = document.querySelectorAll('.container')

  draggables.forEach(draggable => {
    draggable.addEventListener('dragstart', () => {
      draggable.classList.add('dragging')
    })

    draggable.addEventListener('dragend', () => {
      draggable.classList.remove('dragging')
    })
  })

  containers.forEach(container => {
    container.addEventListener('dragover', e => {
      e.preventDefault()
      const afterElement = getDragAfterElement(container, e.clientY)
      const draggable = document.querySelector('.dragging')
      if (afterElement == null) {
        container.appendChild(draggable)
      } else {
        container.insertBefore(draggable, afterElement)
      }
    })
  })

  function getDragAfterElement(container, y) {
    const draggableElements = [...container.querySelectorAll('.draggable:not(.dragging)')]

    return draggableElements.reduce((closest, child) => {
      const box = child.getBoundingClientRect()
      const offset = y - box.top - box.height / 2
      if (offset < 0 && offset > closest.offset) {
        return { offset: offset, element: child }
      } else {
        return closest
      }
    }, { offset: Number.NEGATIVE_INFINITY }).element
  }



  return (
    <div>
      <div className={style.container}>
        <p className={style.draggable} draggable="true">1</p>
        <p className={style.draggable} draggable="true">2</p>
      </div>
    </div>
  )
}

export default Home