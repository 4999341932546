import React, { useState } from 'react'
import AuthLayout from '../layout/AuthLayout'
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu'

import style from '../assets/styles/testCreationStyle.module.css'
import TestList from '../component/molecule/specific/TestCreationScreens/TestList'
import AddTest from '../component/molecule/specific/TestCreationScreens/AddTest'
import EditTest from '../component/molecule/specific/TestCreationScreens/EditTest'
import TestDetails from '../component/molecule/specific/TestCreationScreens/TestDetails'
import { useSelector } from 'react-redux'

const TestCreation = () => {


  const { testScreen } = useSelector((state) => state.GetAllData);

  return (
    <AuthLayout>
      <RightPanelTopMenu title={"Test Templates and Test Papers"} />
      <div className={style.mainScreen}>

        {testScreen === 0 ? <TestList /> : ""}
        {testScreen === 1 ? <AddTest /> : ""}
        {testScreen === 2 ? <TestDetails /> : ""}
        {testScreen === 3 ? <EditTest /> : ""}

      </div>
    </AuthLayout>
  )
}

export default TestCreation