import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'


import style from '../../../assets/styles/modalStyle.module.css';



const MediumModal = ({ forwardButton, modalData, modalMedium, closeModalMedium }) => {

    // ___________ forward button calling on save and make action accordingly ___________
    const ForwardButton = () => {
        forwardButton()
        closeModalMedium()
    }


    // outside clickable close modal 
    const modalRef = useRef();

    const handleOverlayClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModalMedium();
        }
    };

    useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener('mousedown', handleOverlayClick);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleOverlayClick);
        };
    }, [handleOverlayClick]);

    return (
        <>
            {modalMedium ?
                <div className={style.mediumModal}>
                    <div className={style.center_div} ref={modalRef}>
                        <h3>
                            {modalData.title}
                        </h3>
                        <p>
                            {modalData.description}
                        </p>

                        {/* SAVE BUTTON AND CANCEL BUTTON  */}
                        <div className={style.saveButton_questionCreation_Modal}>
                            <div className={` ${style.cancel} ${style.pointer}`} onClick={() => closeModalMedium()}>
                                {modalData.cancelButton}
                            </div>
                            <div>
                                <button className={style.blue_button} onClick={() => ForwardButton()}>  {modalData.saveButton}</button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                ""}

        </>
    )
}

export default MediumModal