import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import IMS_AUTHORING_SAGA from './saga';
import rootReducer from './rootReducer';


const sagaMiddleware = createSagaMiddleware();
const store = configureStore(
    {
        reducer:rootReducer,
        middleware: ()=>[sagaMiddleware]
    }
    )

sagaMiddleware.run(IMS_AUTHORING_SAGA)
export default store;