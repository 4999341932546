import React, { useEffect, useState } from 'react'
import AuthLayout from '../layout/AuthLayout'
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu'
import UserList from '../component/molecule/specific/UserManagementScreens/UserList'
import AddUser from '../component/molecule/specific/UserManagementScreens/AddUser'
import UserDetail from '../component/molecule/specific/UserManagementScreens/UserDeatil'
import EditUser from '../component/molecule/specific/UserManagementScreens/EditUser'
import { CallWithAuth } from '../action/apiActions'
import { GET_USERS } from '../action/apiPath'
import OrganisationList from '../component/molecule/specific/OrganisationManagementScreens/OrganisationList'
import OrganisationDetails from '../component/molecule/specific/OrganisationManagementScreens/OrganisationDetails'


import style from '../assets/styles/organisationManagement.module.css'
import { useSelector } from 'react-redux'

const OrganisationManagement = () => {
   const { organisationScreen } = useSelector((state) => state.GetAllData)
    return (
        <>
            <AuthLayout>
                <RightPanelTopMenu setQuestionBankId={null} title={"Organisation Management"} />

                {/* _____ AFTER TOP BAR SECTION : Dynamic for all screens  ______ */}
                <div className={style.question_bank_table_top}>
                    {organisationScreen === 0 ? <OrganisationList /> : ""}
                    {organisationScreen === 1 ? <OrganisationDetails /> : ""}
                </div>

            </AuthLayout>
        </>
    )
}

export default OrganisationManagement