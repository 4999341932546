// _______________ QUESTION AUTHORING _______________

export const SELECT_QUESTION_BANK= "SELECT_QUESTION_BANK"
export const SELECT_QUESTION_BANK_RESPONSE= "SELECT_QUESTION_BANK_RESPONSE"
export const BASIC_INFORMATION= "BASIC_INFORMATION"
export const QUESTION_CREATION_SCREEN_CHANGE= "QUESTION_CREATION_SCREEN_CHANGE"
export const QUESTION_CREATION_SCREEN_BACK ="QUESTION_CREATION_SCREEN_BACK"
export const QUESTION_CREATION_FINAL_CALL ="QUESTION_CREATION_FINAL_CALL"
export const QUESTION_CREATION_SCREEN_CANCEL ="QUESTION_CREATION_SCREEN_CANCEL"
export const QUESTION_CREATION_SCREEN_CHOOSE_ONLY_BACK ="QUESTION_CREATION_SCREEN_CHOOSE_ONLY_BACK"

// question screen  
export const QUESTION_BANK= "QUESTION_BANK"
export const BASIC_INFO= "BASIC_INFO"
export const QUESTION_CREATION_ONE= "QUESTION_CREATION_ONE"
export const QUESTION_CREATION_TWO= "QUESTION_CREATION_TWO"
export const SEND_FOR_REVIEW= "SEND_FOR_REVIEW"
export const QUESTION_CREATION_CANCEL= "QUESTION_CREATION_CANCEL"
export const QUESTION_CREATION_CHOOSE_BACK= "QUESTION_CREATION_CHOOSE_BACK"

export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';
 
// _______________ REPORT _______________

export const GET_REPORT_IRT = "GET_REPORT_IRT"
export const GET_IRT_REPONSE_SUCCESS = "GET_IRT_REPONSE_SUCCESS"
export const GET_IRT_REPONSE_FAIL = "GET_IRT_REPONSE_FAIL"
export const CHANGE_IRT_SCREEN = "CHANGE_IRT_SCREEN"

export const GET_REPORT_QDR = "GET_REPORT_QDR"
export const GET_QDR_REPONSE_SUCCESS = "GET_QDR_REPONSE_SUCCESS"
export const GET_QDR_REPONSE_FAIL = "GET_QDR_REPONSE_FAIL"
export const CHANGE_QDR_SCREEN = "CHANGE_QDR_SCREEN"


// _______________ TASK MANAGEMENT _______________

export const GET_TASKS_LIST = "GET_TASKS_LIST"
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS"
export const GET_TASKS_FAIL = "GET_TASKS_FAIL"
export const GET_TASKS_DETAIL = "GET_TASKS_DETAIL"
export const GET_TASKS_DETAIL_SUCCESS = "GET_TASKS_DETAIL_SUCCESS"
export const GET_TASKS_DETAIL_FAIL = "GET_TASKS_DETAIL_FAIL"
export const DELETE_TASKS = "DELETE_TASKS"
export const DELETE_TASKS_SUCCESS = "DELETE_TASKS_SUCCESS"
export const DELETE_TASKS_FAIL = "DELETE_TASKS_FAIL"
export const APPROVE_TASKS = "APPROVE_TASKS"
export const APPROVE_TASKS_SUCCESS = "APPROVE_TASKS_SUCCESS"
export const APPROVE_TASKS_FAIL = "APPROVE_TASKS_FAIL"

export const EDIT_TASK = "EDIT_TASK"
export const EDIT_TASK_SUCCESS = "EDIT_TASK_SUCCESS"
export const EDIT_TASK_FAIL = "EDIT_TASK_FAIL"


export const CREATE_TASK = "CREATE_TASK"
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS"
export const CREATE_TASK_FAIL = "CREATE_TASK_FAIL"

export const GET_TASK_SCREEN = "GET_TASK_SCREEN";
export const  GET_TASK_SCREEN_WITH_ID = "GET_TASK_SCREEN_WITH_ID"

export const  GET_TASK_OF_USER = "GET_TASK_OF_USER"


// _______________ REVIEW _______________
export const GET_REVIEW_ALL_DATA = "GET_REVIEW_ALL_DATA"
export const REVIEW_ALLDATA_SUCCESS = "REVIEW_ALLDATA_SUCCESS"
export const REVIEW_ALLDATA_FAIL = "REVIEW_ALLDATA_FAIL"
export const GET_NEED_REVIEW_ALL_DATA = "GET_NEED_REVIEW_ALL_DATA"
export const NEED_REVIEW_ALLDATA_SUCCESS = "NEED_REVIEW_ALLDATA_SUCCESS"
export const NEED_REVIEW_ALLDATA_FAIL = "NEED_REVIEW_ALLDATA_FAIL"
export const CHANGE_REVIEW_STATUS = "CHANGE_REVIEW_STATUS"
export const CHANGE_REVIEW_STATUS_SUCCESS = "CHANGE_REVIEW_STATUS_SUCCESS"
export const CHANGE_REVIEW_STATUS_FAIL = "CHANGE_REVIEW_STATUS_FAIL"
export const CHANGE_QUESTION_CATEGORY = "CHANGE_QUESTION_CATEGORY"
export const TOGGLE_OPEN_STATUS = "TOGGLE_OPEN_STATUS"


// _______________ USER MANAGEMENT _______________
export const GET_USER_LIST = "GET_USER_LIST"
export const GET_USER_LIST_SUCESS = "GET_USER_LIST_SUCESS"
export const GET_USER_LIST_FAIL = "GET_USER_LIST_FAIL"
export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_FAIL = "GET_USER_DETAIL_FAIL"
export const GET_USER_ROLES = "GET_USER_ROLES"
export const GET_USER_ROLES_SUCCESS = "GET_USER_ROLES_SUCCESS"
export const GET_USER_ROLES_FAIL = "GET_USER_ROLES_FAIL"
export const CHANGE_USER_SCREEN = "CHANGE_USER_SCREEN"


// _______________ADD USER _______________
export const GET_USER_QUESTION_BANK =  "GET_USER_QUESTION_BANK"
export const GET_USER_QUESTION_BANK_SUCCESS =  "GET_USER_QUESTION_BANK_SUCCESS"
export const GET_USER_QUESTION_BANK_FAIL =  "GET_USER_QUESTION_BANK_FAIL"
export const GET_ADD_USER = "GET_ADD_USER"
export const GET_ADD_USER_SUCCESS = "GET_ADD_USER_SUCCESS"
export const GET_ADD_USER_FAIL = "GET_ADD_USER_FAIL"
export const EDIT_USER_DETAIL = "EDIT_USER_DETAIL"
export const EDIT_USER_DETAIL_SUCCESS = "EDIT_USER_DETAIL_SUCCESS"
export const EDIT_USER_DETAIL_FAIL = "EDIT_USER_DETAIL_FAI"

// _______________ADD ORGANISATION _______________
export const GET_ORGANISATION_LIST = "GET_ORGANISATION_LIST"
export const GET_ORGANISATION_LIST_SUCCESS = "GET_ORGANISATION_LIST_SUCCESS"
export const GET_ORGANISATION_LIST_FAIL = "GET_ORGANISATION_LIST_FAIL"
export const GET_ORGANISATION_DETAIL = "GET_ORGANISATION_DETAIL"
export const GET_ORGANISATION_DETAIL_SUCCESS = "GET_ORGANISATION_DETAIL_SUCCESS"
export const GET_ORGANISATION_DETAIL_FAIL = "GET_ORGANISATION_DETAIL_FAIL"
export const CHANGE_ORGANISATION_SCREEN= "CHANGE_ORGANISATION_SCREEN"


// _________________ MASTER ________________________
export const CHANGE_MASTER_SCREEN = "CHANGE_MASTER_SCREEN"

export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST"
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS"
export const GET_CATEGORY_LIST_FAIL = "GET_CATEGORY_LIST_FAIL"

export const GET_IDENTIFIER_LIST = "GET_IDENTIFIER_LIST"
export const GET_IDENTIFIER_LIST_SUCCESS = "GET_IDENTIFIER_LIST_SUCCESS"
export const GET_IDENTIFIER_LIST_FAIL = "GET_IDENTIFIER_LIST_FAIL"

export const GET_LOD_LIST = "GET_LOD_LIST"
export const GET_LOD_LIST_SUCCESS = "GET_LOD_LIST_SUCCESS"
export const GET_LOD_LIST_FAIL = "GET_LOD_LIST_FAIL"

export const GET_SUBJECT_PIPELINE_LIST = "GET_SUBJECT_PIPELINE_LIST"
export const GET_SUBJECT_PIPELINE_LIST_SUCCESS = "GET_SUBJECT_PIPELINE_LIST_SUCCESS"
export const GET_SUBJECT_PIPELINE_LIST_FAIL = "GET_SUBJECT_PIPELINE_LIST_FAIL"

export const GET_QUESTION_BANK_LIST = "GET_QUESTION_BANK_LIST"
export const GET_QUESTION_BANK_LIST_SUCCESS = "GET_QUESTION_BANK_LIST_SUCCESS"
export const GET_QUESTION_BANK_LIST_FAIL = "GET_QUESTION_BANK_LIST_FAIL"

export const GET_PRODUCT_TYPE = "GET_PRODUCT_TYPE"
export const GET_PRODUCT_TYPE_SUCCESS = "GET_PRODUCT_TYPE_SUCCESS"
export const GET_PRODUCT_TYPE_FAIL = "GET_PRODUCT_TYPE_FAIL"


// ______________________________ TEST CREATION ___________//

export const CREATE_TEST_BASIC_SETTING = "CREATE_TEST_BASIC_SETTING"
export const CREATE_TEST_BASIC_SETTING_SUCCESS = "CREATE_TEST_BASIC_SETTING_SUCCESS"
export const CREATE_TEST_BASIC_SETTING_FAIL = "CREATE_TEST_BASIC_SETTING_FAIL"

export const PUBLISH_TEST = "PUBLISH_TEST"
export const PUBLISH_TEST_SUCCESS = "PUBLISH_TEST_SUCCESS"
export const PUBLISH_TEST_FAIL = "PUBLISH_TEST_FAIL"

export const CHANGE_TEST_SCREEN = "CHANGE_TEST_SCREEN"
export const CHANGE_ADD_TEST_SCREEN = "CHANGE_ADD_TEST_SCREEN"
export const CHANGE_EDIT_TEST_SCREEN ="CHANGE_EDIT_TEST_SCREEN"

export const CREATE_TEST_SECTION = "CREATE_TEST_SECTION"
export const CREATE_TEST_SECTION_SUCCESS = "CREATE_TEST_SECTION_SUCCESS"
export const CREATE_TEST_SECTION_FAIL = "CREATE_TEST_SECTION_FAIL"

export const EDIT_CREATE_TEST_SECTION="EDIT_CREATE_TEST_SECTION"
export const EDIT_CREATE_TEST_SECTION_SUCCESS="EDIT_CREATE_TEST_SECTION_SUCCESS"
export const EDIT_CREATE_TEST_SECTION_FAIL="EDIT_CREATE_TEST_SECTION_FAIL"

export const GET_TEST_LISTING = "GET_TEST_LISTING"
export const GET_TEST_LISTING_SUCCESS = "GET_TEST_LISTING_SUCCESS"
export const GET_TEST_LISTING_FAIL = "GET_TEST_LISTING_FAIL"

export const DELETE_TEST = "DELETE_TEST"
export const DELETE_TEST_SUCCESS = "DELETE_TEST_SUCCESS"
export const DELETE_TEST_FAIL = "DELETE_TEST_FAIL"

export const UNPUBLISH_TEST = "UNPUBLISH_TEST"
export const UNPUBLISH_TEST_SUCCESS = "UNPUBLISH_TEST_SUCCESS"
export const UNPUBLISH_TEST_FAIL = "UNPUBLISH_TEST_FAIL"

export const PUBLISHED_TEST = "PUBLISHED_TEST"
export const PUBLISHED_TEST_SUCCESS = "PUBLISHED_TEST_SUCCESS"
export const PUBLISHED_TEST_FAIL = "PUBLISHED_TEST_FAIL"


export const DUPLICATE_TEST = "DUPLICATE_TEST"
export const DUPLICATE_TEST_SUCCESS = "DUPLICATE_TEST_SUCCESS"
export const DUPLICATE_TEST_FAIL = "DUPLICATE_TEST_FAIL"


export const PRODUCT_TYPE = "PRODUCT_TYPE"
export const PRODUCT_TYPE_SUCCUSS = "PRODUCT_TYPE_SUCCUSS"
export const PRODUCT_TYPE_FAIL = "PRODUCT_TYPE_FAIL"

export const GET_TEST_DETAILS = "GET_TEST_DETAILS"
export const GET_TEST_DETAILS_SUCCESS = "GET_TEST_DETAILS_SUCCESS"
export const GET_TEST_DETAILS_FAIL = "GET_TEST_DETAILS_FAIL"


export const GET_REPLACE_QUESTION = "GET_REPLACE_QUESTION"
export const GET_REPLACE_QUESTION_SUCCESS = "GET_REPLACE_QUESTION_SUCCESS"
export const GET_REPLACE_QUESTION_FAIL = "GET_REPLACE_QUESTION_FAIL"

export const GET_TETS_DETAILS_FOR_EDIT = "GET_TETS_DETAILS_FOR_EDIT"
export const GET_TETS_DETAILS_FOR_EDIT_SUCCESS = "GET_TETS_DETAILS_FOR_EDIT_SUCCESS"
export const GET_TETS_DETAILS_FOR_EDIT_FAIL = "GET_TETS_DETAILS_FOR_EDIT_FAIL"

export const UPDATE_BASIC_SETTINGS = "UPDATE_BASIC_SETTINGS"
export const UPDATE_BASIC_SETTINGS_SUCCESS= "UPDATE_BASIC_SETTINGS_SUCCESS"
export const UPDATE_BASIC_SETTINGS_FAIL = "UPDATE_BASIC_SETTINGS_FAIL"

