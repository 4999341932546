
import React, { useEffect, useState } from 'react'
import AuthLayout from '../layout/AuthLayout'
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu'
import UserList from '../component/molecule/specific/UserManagementScreens/UserList'
import AddUser from '../component/molecule/specific/UserManagementScreens/AddUser'
import UserDetail from '../component/molecule/specific/UserManagementScreens/UserDeatil'
import EditUser from '../component/molecule/specific/UserManagementScreens/EditUser'
import style from '../assets/styles/userManagement.module.css';
import { useSelector } from 'react-redux'

const UserManagement = () => {
    // ________________ screen to show dynamically ________________
    const { userScreen } = useSelector((state) => state.GetAllData);
  
  
    return (
        <>
            <AuthLayout>
                {/* rightpannel top left menu according to different screen  */}
                <RightPanelTopMenu setQuestionBankId={null} title={"User Management"} />

                {/* _____ AFTER TOP BAR SECTION : Dynamic for all screens  ______ */}
                <div className={style.question_bank_table_top}>
                    {userScreen === 0 ? <UserList/> : ""}
                    {userScreen === 1 ? <AddUser /> : ""}
                    {userScreen === 2 ? <UserDetail /> : ""}
                    {userScreen === 3 ? <EditUser /> : ""}
                </div>

            </AuthLayout>
        </>
    )
}

export default UserManagement