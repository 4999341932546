import React, { useEffect, useRef } from "react";
import { useState } from "react";

import style from "../../../assets/styles/modalStyle.module.css";

const TaskFilterModal = ({ modal, closeModal, type, FunctionCall }) => {
    // ________________ Initialization of FREE TAG ________________
    const modalRef = useRef();
    return (
        <div className={style.filterModal}>
            <div
                style={{ width: "475px", height: "420px" }}
                className={style.center_div}
                ref={modalRef}
            >
                <div className={style.topTask}>
                    <div className={style.bound}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.39844 2.10156H18.5984C19.6984 2.10156 20.5984 3.00156 20.5984 4.10156V6.30156C20.5984 7.10156 20.0984 8.10156 19.5984 8.60156L15.2984 12.4016C14.6984 12.9016 14.2984 13.9016 14.2984 14.7016V19.0016C14.2984 19.6016 13.8984 20.4016 13.3984 20.7016L11.9984 21.6016C10.6984 22.4016 8.89844 21.5016 8.89844 19.9016V14.6016C8.89844 13.9016 8.49844 13.0016 8.09844 12.5016L4.29844 8.50156C3.79844 8.00156 3.39844 7.10156 3.39844 6.50156V4.20156C3.39844 3.00156 4.29844 2.10156 5.39844 2.10156Z"
                                stroke="#212529"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>

                        <span>Filter</span>
                    </div>

                    {/* __________ close __________  */}
                    <div className={style.close}>
                        <button onClick={() => closeModal()}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.34375 6.34375L17.6575 17.6575"
                                    stroke="#212529"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M6.34254 17.6575L17.6562 6.34375"
                                    stroke="#212529"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                    </div>

                    {/* __________ title __________ */}
                </div>

                <div className={style.master_modal}>
                    <span style={{ fontSize: "12px", fontWeight: 500 }}>
                        Question bank(s){" "}
                    </span>
                    <div className={style.search_listing}>
                        <div className={style.input_search}>
                            <svg
                                style={{ marginTop: "13px", marginLeft: "10px" }}
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="20" height="20" rx="4" fill="#272727" />
                                <path
                                    d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                    fill="white"
                                />
                            </svg>
                            <input
                                type="search"
                                id="searchInput"
                                placeholder="Choose task label"
                            />
                        </div>
                    </div>

                    <span style={{ fontSize: "12px", fontWeight: 500 }}>
                        Question Author(s){" "}
                    </span>
                    <div className={style.search_listing}>
                        <div className={style.input_search}>
                            <svg
                                style={{ marginTop: "13px", marginLeft: "10px" }}
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="20" height="20" rx="4" fill="#272727" />
                                <path
                                    d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                                    fill="white"
                                />
                            </svg>
                            <input
                                type="search"
                                id="searchInput"
                                placeholder="Choose task label"
                            />
                        </div>
                    </div>

                    <div
                        className={` ${style.saveButton_questionBank} ${style.margin_top_20}`}
                    >
                        <div
                            className={` ${style.cancel} ${style.pointer}`}
                            onClick={() => closeModal()}
                        >
                            Reset Filter
                        </div>
                        <div>
                            <button className={style.blue_button}>Apply filter</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaskFilterModal;
