import React, { useEffect } from 'react'
import AuthLayout from '../layout/AuthLayout'
import { useNavigate } from 'react-router-dom'
import { CallWithAuth } from '../action/apiActions'
import { GET_QUESTION_BANK, GET_QUESTIONBANK, GET_SUBJECT_TAG } from '../action/apiPath'
import { useState } from 'react'
import RightPanelTopMenu from '../component/molecule/common/RightPanelTopMenu'
import Category from '../component/molecule/specific/MastersManagementScreens/Category'
import Identifier from '../component/molecule/specific/MastersManagementScreens/Identifier'
import LOD from '../component/molecule/specific/MastersManagementScreens/LOD'
import QuestionBank from '../component/molecule/specific/MastersManagementScreens/QuestionBank'
import SubjectPipeline from '../component/molecule/specific/MastersManagementScreens/SubjectPipeline'


import style from '../assets/styles/masterTemplate.module.css'
import { useSelector } from 'react-redux'

const Masters = () => {

    // _________question bank id which is top dropdown _________
    // const [masterSelection, setMasterSelection] = useState(1)

    const {masterScreen} = useSelector((state)=>state.GetAllData)

    return (
        <>
            <AuthLayout>
                {/* rightpannel top left menu according to different screen  */}
                <RightPanelTopMenu title={"Masters"} />

                {/* different screens according to the selection of masters  */}
                <div className={style.Master_div}>
                    {masterScreen === 1 ? <Category /> : ""}
                    {masterScreen === 2 ? <Identifier /> : ""}
                    {masterScreen === 3 ? <LOD /> : ""}
                    {masterScreen === 4 ? <QuestionBank /> : ""}
                    {masterScreen === 5 ? <SubjectPipeline /> : ""}
                </div>

            </AuthLayout>
        </>
    )
}

export default Masters