import React, { useEffect, useRef, useState } from "react";

import style from '../../../../assets/styles/reportStyle.module.css'
import { GET_AUTHOR, GET_CATEGORY, GET_FREE_TAG, GET_REPORT_DATA, GET_SUBJECT_TAG } from "../../../../action/apiPath";
import { CallWithAuth } from "../../../../action/apiActions";
import { useDispatch, useSelector } from "react-redux";
import { Change_irt_status, Change_qdr_status, GetReport, Get_IRT_Report, Get_IRT_parameter } from "../../../../redux/action";
import FilterModal from "../../../atoms/Modal/FilterModal";
import style2 from '../../../../assets/styles/reportFilterScreen.module.css';

const IRTparameters = () => {


    //  show data of all
    const { irtList } = useSelector((state) => state.GetAllData)

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Get_IRT_Report())
    }, [])


    // _______________ Filter state ______________
    const [multiselectedSubjectTag, setmultiSelectedSubjectTag] = useState([])
    const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([])
    const [multiselectedAuthorTag, setmultiSelectedAuthorTag] = useState([])
    const [multiselectedCategoryTag, setmultiSelectedCategoryTag] = useState([])
    // Get the current year
    const currentYear = new Date().getFullYear();
    // Initialize state for the selected year
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedYearExclude, setSelectedYearExclude] = useState(currentYear);


    // ________ filter modal open  ________
    const [modalFilter, setModalFilter] = useState(false)

    const openModalFilter = () => {
        setModalFilter(true)
    }

    const closeModalFilter = () => {
        setModalFilter(false)
    }


    // ________________________________ FILTER SCREEN CHECK ________________________________
    const { irtFilterScreen } = useSelector((state) => state.GetAllData)

    // _________ PRIMARY TAG STATE INITIALIZATION _________
    const [multisubjectTaginput, setmultiSubjectTaginput] = useState("")
    const [multisubjectTag, setmultiSubjectTag] = useState([])
    const [multisubjectTagStatus, setmultiSubjectTagStatus] = useState(false)
    // const [multiselectedSubjectTag, setmultiSelectedSubjectTag] = useState([])

    // CALL GET API FOR getting TAGS 
    const TagSubjectMultiple = async (e) => {
        setmultiSubjectTaginput(e)
        if (e.length > 2) {
            const data = await CallWithAuth("GET", GET_SUBJECT_TAG + "?keyword=" + e)
            // console.log(data)
            if (data.status && data.res.status === 200) {
                setmultiSubjectTag(data.res.data)
                if (data.res.data.length > 0) {
                    setmultiSubjectTagStatus(true)
                } else {
                    setmultiSubjectTagStatus(false)
                }
            }
        } else {
            setmultiSubjectTagStatus(false)
        }


    }


    // select tags from options 
    const selectSubjectTag = (e) => {
        setmultiSelectedSubjectTag(current => [...current, e])
        setmultiSubjectTagStatus(false)
        setmultiSubjectTaginput("")
    }






    // _________ Free TAG STATE INITIALIZATION _________
    const [multiFreeTaginput, setmultiFreeTaginput] = useState("")
    const [multiFreeTag, setmultiFreeTag] = useState([])
    const [multiFreeTagStatus, setmultiFreeTagStatus] = useState(false)
    // const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([])

    // CALL GET API FOR getting TAGS 
    const TagFreeMultiple = async (e) => {
        setmultiFreeTaginput(e)
        if (e.length > 2) {
            const data = await CallWithAuth("GET", GET_FREE_TAG + "?keyword=" + e)
            // console.log(data)
            if (data.status && data.res.status === 200) {
                setmultiFreeTag(data.res.data)
                if (data.res.data.length > 0) {
                    setmultiFreeTagStatus(true)
                } else {
                    setmultiFreeTagStatus(false)
                }
            }
        } else {
            setmultiFreeTagStatus(false)
        }


    }


    // select tags from options 
    const selectFreeTag = (e) => {
        setmultiSelectedFreeTag(current => [...current, e])
        setmultiFreeTagStatus(false)
        setmultiFreeTaginput("")
    }



    // _________ Category TAG STATE INITIALIZATION _________
    const [multiCategoryTaginput, setmultiCategoryTaginput] = useState("")
    const [multiCategoryTag, setmultiCategoryTag] = useState([])
    const [multiCategoryTagStatus, setmultiCategoryTagStatus] = useState(false)
    // const [multiselectedCategoryTag, setmultiSelectedCategoryTag] = useState([])

    // CALL GET API FOR getting TAGS 
    const TagCategoryMultiple = async (e) => {
        setmultiCategoryTaginput(e)
        if (e.length > 2) {
            const data = await CallWithAuth("GET", GET_CATEGORY + "?keyword=" + e)
            // console.log(data)
            if (data.status && data.res.status === 200) {
                setmultiCategoryTag(data.res.data)
                if (data.res.data.length > 0) {
                    setmultiCategoryTagStatus(true)
                } else {
                    setmultiCategoryTagStatus(false)
                }
            }
        } else {
            setmultiCategoryTagStatus(false)
        }


    }


    // select tags from options 
    const selectCategoryTag = (e) => {
        setmultiSelectedCategoryTag(current => [...current, e])
        setmultiCategoryTagStatus(false)
        setmultiCategoryTaginput("")
    }




    // _________ AUTHIR TAG STATE INITIALIZATION _________
    const [multiAuthorTaginput, setmultiAuthorTaginput] = useState("")
    const [multiAuthorTag, setmultiAuthorTag] = useState([])
    const [multiAuthorTagStatus, setmultiAuthorTagStatus] = useState(false)
    // const [multiselectedAuthorTag, setmultiSelectedAuthorTag] = useState([])

    // CALL GET API FOR getting TAGS 
    const TagAuthorMultiple = async (e) => {
        setmultiAuthorTaginput(e)
        if (e.length > 2) {
            const data = await CallWithAuth("GET", GET_AUTHOR + "?keyword=" + e)
            // console.log(data)
            if (data.status && data.res.status === 200) {
                setmultiAuthorTag(data.res.data)
                if (data.res.data.length > 0) {
                    setmultiAuthorTagStatus(true)
                } else {
                    setmultiAuthorTagStatus(false)
                }
            }
        } else {
            setmultiAuthorTagStatus(false)
        }


    }


    // select tags from options 
    const selectAuthorTag = (e) => {
        setmultiSelectedAuthorTag(current => [...current, e])
        setmultiAuthorTagStatus(false)
        setmultiAuthorTaginput("")
    }




    // _________ YEAR USED-> STATE INITIALIZATION _________

    // Get the current year
    // const currentYear = new Date().getFullYear();

    // Initialize state for the selected year
    // const [selectedYear, setSelectedYear] = useState(currentYear);
    // const [selectedYearExclude, setSelectedYearExclude] = useState(currentYear);

    // Create an array of years from 2000 to 20 years after the current year
    const yearOptions = [];
    for (let year = 2000; year <= currentYear + 20; year++) {
        yearOptions.push(year);
    }

    // Event handler for changing the selected year
    const handleYearChangeUsed = (e) => {
        setSelectedYear(parseInt(e.target.value, 10));
    };


    // Event handler for changing the selected year
    const handleYearChangeExclude = (e) => {
        setSelectedYearExclude(parseInt(e.target.value, 10));
    };



    const SaveFilter = () => {
        dispatch(Change_irt_status(false))
    }




    const modalRef = useRef();

    const handleOverlayClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModalFilter();
        }
    };

    useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener('mousedown', handleOverlayClick);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleOverlayClick);
        };
    }, [handleOverlayClick]);



    // __________________ outside clickable close modal _____________________

    const rowRef = useRef();

    // const handleRowClick = (event) => {
    //     if (rowRef.current && !rowRef.current.contains(event.target)) {
    //         // Check if the click is within the action buttons
    //         const isActionButtonClick = event.target.closest('.action_buttons');

    //         if (!isActionButtonClick) {
    //             setmultiSubjectTagStatus(false)
    //             setmultiCategoryTagStatus(false)
    //             setmultiFreeTagStatus(false)
    //             setmultiAuthorTagStatus(false)
    //         }
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleRowClick);

    //     return () => {
    //         document.removeEventListener('mousedown', handleRowClick);
    //     };
    // }, [handleRowClick]);

    return (
        <>

            {irtFilterScreen ?
                <div className={style2.filterModal}>
                    <div className={style2.center_div} ref={modalRef}>

                        {/* __________ modal bottom FIELD __________ */}
                        <div className={style2.bottom_fields}>

                            {/* subject tag  */}
                            <div className={style2.tags}>
                                <div className={style2.subject}>
                                    <p>Subject | Area | Topic | Subtopic </p>
                                    <div className={style2.search_listing}>
                                        <div className={style2.tag_icons}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="20" height="20" rx="4" fill="#272727" />
                                                <path d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z" fill="white" />
                                            </svg>


                                        </div>

                                        <div className={style2.input_search} ref={rowRef}>

                                            {multiselectedSubjectTag?.map((items, i) => (
                                                <>
                                                    <span className={style2.selected_tags}>{items.subject} |  {items.area} | {items.topic} | {items.subtopic} </span>
                                                </>
                                            ))}

                                            <input type='search' id='searchInput' placeholder='' value={multisubjectTaginput} onChange={(e) => TagSubjectMultiple(e.target.value)} />


                                            {multisubjectTagStatus ?
                                                <div className={style2.list_input_primary}>
                                                    {multisubjectTag?.filter(item => !multiselectedSubjectTag.some(filterItem => item.id === filterItem.id)).map((items, i) => (
                                                        <>
                                                            <div onClick={() => selectSubjectTag(items)}><span>{items.subject} | {items.area} | {items.topic} | {items.subtopic}  </span></div>
                                                        </>
                                                    ))}
                                                </div>
                                                : ""}

                                        </div>

                                        <div className={style2.cancel_button}>
                                            <button onClick={() => setmultiSelectedSubjectTag([])}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.75781 7.75781L16.2431 16.2431" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M7.75691 16.2431L16.2422 7.75781" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </button>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            {/* used id  */}
                            <div className={style2.drop}>
                                <div className={style2.drop_item}>
                                    <div className={style2.subject}>
                                        <p>Last Used In  </p>
                                        <select name="question" id="question" className={style2.header_drop_filter} value={selectedYear} onChange={handleYearChangeUsed} >
                                            {yearOptions.map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className={style2.drop_item}>
                                    <div className={style2.subject}>
                                        <p>Exclude Questions Used Since  </p>
                                        <select name="question" id="question" className={style2.header_drop_filter} value={selectedYearExclude} onChange={handleYearChangeExclude} >
                                            {yearOptions.map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* category and tag  */}
                            <div className={style2.drop}>

                                <div className={style2.tags}>
                                    <div className={style2.subject}>
                                        <p>Category </p>
                                        <div className={style2.search_listing}>
                                            <div className={style2.tag_icons}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="20" height="20" rx="4" fill="#272727" />
                                                    <path d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z" fill="white" />
                                                </svg>


                                            </div>

                                            <div className={style2.input_search}>

                                                {multiselectedCategoryTag?.map((items, i) => (
                                                    <>
                                                        <span className={style2.selected_tags}>{items.name}  </span>
                                                    </>
                                                ))}

                                                <input type='search' id='searchInput' placeholder='' value={multiCategoryTaginput} onChange={(e) => TagCategoryMultiple(e.target.value)} />


                                                {multiCategoryTagStatus ?
                                                    <div className={style2.list_input_primary}>
                                                        {multiCategoryTag?.filter(item => !multiselectedCategoryTag.some(filterItem => item.id === filterItem.id)).map((items, i) => (
                                                            <>
                                                                <div onClick={() => selectCategoryTag(items)}><span>{items.name}  </span></div>
                                                            </>
                                                        ))}
                                                    </div>
                                                    : ""}

                                            </div>

                                            <div className={style2.cancel_button}>
                                                <button onClick={() => setmultiSelectedCategoryTag([])}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.75781 7.75781L16.2431 16.2431" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7.75691 16.2431L16.2422 7.75781" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div className={style2.tags}>
                                    <div className={style2.subject}>
                                        <p>Tags </p>
                                        <div className={style2.search_listing}>
                                            <div className={style2.tag_icons}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="20" height="20" rx="4" fill="#272727" />
                                                    <path d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z" fill="white" />
                                                </svg>


                                            </div>

                                            <div className={style2.input_search}>

                                                {multiselectedFreeTag?.map((items, i) => (
                                                    <>
                                                        <span className={style2.selected_tags}>{items.name}  </span>
                                                    </>
                                                ))}

                                                <input type='search' id='searchInput' placeholder='' value={multiFreeTaginput} onChange={(e) => TagFreeMultiple(e.target.value)} />


                                                {multiFreeTagStatus ?
                                                    <div className={style2.list_input_primary}>
                                                        {multiFreeTag?.filter(item => !multiselectedFreeTag.some(filterItem => item.id === filterItem.id)).map((items, i) => (
                                                            <>
                                                                <div onClick={() => selectFreeTag(items)}><span>{items.name}  </span></div>
                                                            </>
                                                        ))}
                                                    </div>
                                                    : ""}

                                            </div>

                                            <div className={style2.cancel_button}>
                                                <button onClick={() => setmultiSelectedFreeTag([])}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.75781 7.75781L16.2431 16.2431" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7.75691 16.2431L16.2422 7.75781" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                            {/* question author  */}
                            <div className={style2.tags}>
                                <div className={style2.subject}>
                                    <p>Question Author(s)  </p>
                                    <div className={style2.search_listing}>
                                        <div className={style2.tag_icons}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="20" height="20" rx="4" fill="#272727" />
                                                <path d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z" fill="white" />
                                            </svg>


                                        </div>

                                        <div className={style2.input_search}>

                                            {multiselectedAuthorTag?.map((items, i) => (
                                                <>
                                                    <span className={style2.selected_tags}>{items.first_name}-{items.last_name}  </span>
                                                </>
                                            ))}

                                            <input type='search' id='searchInput' placeholder='' value={multiAuthorTaginput} onChange={(e) => TagAuthorMultiple(e.target.value)} />


                                            {multiAuthorTagStatus ?
                                                <div className={style2.list_input_primary}>
                                                    {multiAuthorTag?.filter(item => !multiselectedAuthorTag.some(filterItem => item.id === filterItem.id)).map((items, i) => (
                                                        <>
                                                            <div onClick={() => selectAuthorTag(items)}><span>{items.first_name}-{items.last_name}  </span></div>
                                                        </>
                                                    ))}
                                                </div>
                                                : ""}

                                        </div>

                                        <div className={style2.cancel_button}>
                                            <button onClick={() => setmultiSelectedAuthorTag([])}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.75781 7.75781L16.2431 16.2431" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M7.75691 16.2431L16.2422 7.75781" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* __________ apply and save button __________ */}
                            <div>
                                <button className={style2.blue_button} onClick={() => SaveFilter()}>Apply Selected Filter</button>
                            </div>

                        </div>
                    </div>
                </div>
                :
                <div className={style.irt_params} >
                    <div className={style.param_container}>
                        <div className={style.student_report_form}>
                            <header className={style.user_report_header}>
                                <div className={style.header_content}>
                                    <span className={style.irt_title}>IRT Parameter</span>
                                    <span className={style.view_report}>View report History</span>
                                </div>

                                <div className={style.user_right_options}>
                                    <span onClick={() => openModalFilter()}>
                                        <svg
                                            width="20"
                                            height="22"
                                            viewBox="0 0 20 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M3.39844 1.10156H16.5984C17.6984 1.10156 18.5984 2.00156 18.5984 3.10156V5.30156C18.5984 6.10156 18.0984 7.10156 17.5984 7.60156L13.2984 11.4016C12.6984 11.9016 12.2984 12.9016 12.2984 13.7016V18.0016C12.2984 18.6016 11.8984 19.4016 11.3984 19.7016L9.99844 20.6016C8.69844 21.4016 6.89844 20.5016 6.89844 18.9016V13.6016C6.89844 12.9016 6.49844 12.0016 6.09844 11.5016L2.29844 7.50156C1.79844 7.00156 1.39844 6.10156 1.39844 5.50156V3.20156C1.39844 2.00156 2.29844 1.10156 3.39844 1.10156Z"
                                                stroke="#212529"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>{" "}
                                        <span className={style.student_report_filter} >Filter
                                        </span>
                                    </span>

                                    <button className={style.download_report_bt}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12.1211 15.6899V4.00781"
                                                stroke="white"
                                                stroke-width="1.8"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M15.0371 12.8477L12.1211 15.6884L9.20511 12.8477"
                                                stroke="white"
                                                stroke-width="1.8"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M16.7541 8.60156H17.6871C18.171 8.60156 18.6501 8.69404 19.0971 8.87372C19.5442 9.05341 19.9503 9.31677 20.2924 9.64876C20.6345 9.98076 20.9059 10.3749 21.091 10.8086C21.276 11.2424 21.3712 11.7073 21.3711 12.1767V16.9152C21.3711 17.8608 20.9839 18.7677 20.2947 19.4363C19.6055 20.105 18.6708 20.4806 17.6961 20.4806H6.55609C5.57877 20.4806 4.64148 20.1039 3.95041 19.4335C3.25933 18.763 2.87109 17.8536 2.87109 16.9055V12.1661C2.87136 11.2206 3.25866 10.314 3.94783 9.64552C4.637 8.97708 5.5716 8.60156 6.54609 8.60156H7.48809"
                                                stroke="white"
                                                stroke-width="1.8"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        Download report
                                    </button>
                                </div>
                            </header>

                            <div className={style.user_report_table}>
                                <table>

                                    <thead>
                                        <tr>
                                            <th id="one">
                                                Question Id{" "}
                                                <svg
                                                    width="18"
                                                    height="12"
                                                    viewBox="0 0 18 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.85 2.36369L14.85 11.3077C14.85 11.69 14.5478 12 14.175 12C13.8022 12 13.5 11.69 13.5 11.3077L13.5 2.36369L11.5023 4.41261C11.2387 4.68298 10.8113 4.68298 10.5477 4.41261C10.2841 4.14225 10.2841 3.7039 10.5477 3.43354L13.6977 0.202771C13.9613 -0.0675907 14.3887 -0.0675907 14.6523 0.202771L17.8023 3.43354C18.0659 3.7039 18.0659 4.14225 17.8023 4.41261C17.5387 4.68298 17.1113 4.68298 16.8477 4.41261L14.85 2.36369Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 10.8462C0 11.2285 0.302208 11.5385 0.675 11.5385H9.225C9.59779 11.5385 9.9 11.2285 9.9 10.8462C9.9 10.4638 9.59779 10.1538 9.225 10.1538H0.675C0.302208 10.1538 0 10.4638 0 10.8462Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 6.23077C0 6.61312 0.302208 6.92308 0.675 6.92308H5.625C5.99779 6.92308 6.3 6.61312 6.3 6.23077C6.3 5.84842 5.99779 5.53846 5.625 5.53846H0.675C0.302208 5.53846 0 5.84842 0 6.23077Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 1.61538C0 1.99774 0.302208 2.30769 0.675 2.30769H3.825C4.19779 2.30769 4.5 1.99774 4.5 1.61538C4.5 1.23303 4.19779 0.923077 3.825 0.923077H0.675C0.302208 0.923077 0 1.23303 0 1.61538Z"
                                                        fill="#00ABFB"
                                                    />
                                                </svg>
                                            </th>
                                            <th id="two">
                                                No.of time administered{" "}
                                                <svg
                                                    width="18"
                                                    height="12"
                                                    viewBox="0 0 18 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.85 2.36369L14.85 11.3077C14.85 11.69 14.5478 12 14.175 12C13.8022 12 13.5 11.69 13.5 11.3077L13.5 2.36369L11.5023 4.41261C11.2387 4.68298 10.8113 4.68298 10.5477 4.41261C10.2841 4.14225 10.2841 3.7039 10.5477 3.43354L13.6977 0.202771C13.9613 -0.0675907 14.3887 -0.0675907 14.6523 0.202771L17.8023 3.43354C18.0659 3.7039 18.0659 4.14225 17.8023 4.41261C17.5387 4.68298 17.1113 4.68298 16.8477 4.41261L14.85 2.36369Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 10.8462C0 11.2285 0.302208 11.5385 0.675 11.5385H9.225C9.59779 11.5385 9.9 11.2285 9.9 10.8462C9.9 10.4638 9.59779 10.1538 9.225 10.1538H0.675C0.302208 10.1538 0 10.4638 0 10.8462Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 6.23077C0 6.61312 0.302208 6.92308 0.675 6.92308H5.625C5.99779 6.92308 6.3 6.61312 6.3 6.23077C6.3 5.84842 5.99779 5.53846 5.625 5.53846H0.675C0.302208 5.53846 0 5.84842 0 6.23077Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 1.61538C0 1.99774 0.302208 2.30769 0.675 2.30769H3.825C4.19779 2.30769 4.5 1.99774 4.5 1.61538C4.5 1.23303 4.19779 0.923077 3.825 0.923077H0.675C0.302208 0.923077 0 1.23303 0 1.61538Z"
                                                        fill="#00ABFB"
                                                    />
                                                </svg>
                                            </th>
                                            <th id="three">
                                                Attempt %{" "}
                                                <svg
                                                    width="18"
                                                    height="12"
                                                    viewBox="0 0 18 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.85 2.36369L14.85 11.3077C14.85 11.69 14.5478 12 14.175 12C13.8022 12 13.5 11.69 13.5 11.3077L13.5 2.36369L11.5023 4.41261C11.2387 4.68298 10.8113 4.68298 10.5477 4.41261C10.2841 4.14225 10.2841 3.7039 10.5477 3.43354L13.6977 0.202771C13.9613 -0.0675907 14.3887 -0.0675907 14.6523 0.202771L17.8023 3.43354C18.0659 3.7039 18.0659 4.14225 17.8023 4.41261C17.5387 4.68298 17.1113 4.68298 16.8477 4.41261L14.85 2.36369Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 10.8462C0 11.2285 0.302208 11.5385 0.675 11.5385H9.225C9.59779 11.5385 9.9 11.2285 9.9 10.8462C9.9 10.4638 9.59779 10.1538 9.225 10.1538H0.675C0.302208 10.1538 0 10.4638 0 10.8462Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 6.23077C0 6.61312 0.302208 6.92308 0.675 6.92308H5.625C5.99779 6.92308 6.3 6.61312 6.3 6.23077C6.3 5.84842 5.99779 5.53846 5.625 5.53846H0.675C0.302208 5.53846 0 5.84842 0 6.23077Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 1.61538C0 1.99774 0.302208 2.30769 0.675 2.30769H3.825C4.19779 2.30769 4.5 1.99774 4.5 1.61538C4.5 1.23303 4.19779 0.923077 3.825 0.923077H0.675C0.302208 0.923077 0 1.23303 0 1.61538Z"
                                                        fill="#00ABFB"
                                                    />
                                                </svg>
                                            </th>
                                            <th id="four">
                                                Correct %{" "}
                                                <svg
                                                    width="18"
                                                    height="12"
                                                    viewBox="0 0 18 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.85 2.36369L14.85 11.3077C14.85 11.69 14.5478 12 14.175 12C13.8022 12 13.5 11.69 13.5 11.3077L13.5 2.36369L11.5023 4.41261C11.2387 4.68298 10.8113 4.68298 10.5477 4.41261C10.2841 4.14225 10.2841 3.7039 10.5477 3.43354L13.6977 0.202771C13.9613 -0.0675907 14.3887 -0.0675907 14.6523 0.202771L17.8023 3.43354C18.0659 3.7039 18.0659 4.14225 17.8023 4.41261C17.5387 4.68298 17.1113 4.68298 16.8477 4.41261L14.85 2.36369Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 10.8462C0 11.2285 0.302208 11.5385 0.675 11.5385H9.225C9.59779 11.5385 9.9 11.2285 9.9 10.8462C9.9 10.4638 9.59779 10.1538 9.225 10.1538H0.675C0.302208 10.1538 0 10.4638 0 10.8462Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 6.23077C0 6.61312 0.302208 6.92308 0.675 6.92308H5.625C5.99779 6.92308 6.3 6.61312 6.3 6.23077C6.3 5.84842 5.99779 5.53846 5.625 5.53846H0.675C0.302208 5.53846 0 5.84842 0 6.23077Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 1.61538C0 1.99774 0.302208 2.30769 0.675 2.30769H3.825C4.19779 2.30769 4.5 1.99774 4.5 1.61538C4.5 1.23303 4.19779 0.923077 3.825 0.923077H0.675C0.302208 0.923077 0 1.23303 0 1.61538Z"
                                                        fill="#00ABFB"
                                                    />
                                                </svg>
                                            </th>
                                            <th id="five">
                                                A Value{" "}
                                                <svg
                                                    width="18"
                                                    height="12"
                                                    viewBox="0 0 18 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.85 2.36369L14.85 11.3077C14.85 11.69 14.5478 12 14.175 12C13.8022 12 13.5 11.69 13.5 11.3077L13.5 2.36369L11.5023 4.41261C11.2387 4.68298 10.8113 4.68298 10.5477 4.41261C10.2841 4.14225 10.2841 3.7039 10.5477 3.43354L13.6977 0.202771C13.9613 -0.0675907 14.3887 -0.0675907 14.6523 0.202771L17.8023 3.43354C18.0659 3.7039 18.0659 4.14225 17.8023 4.41261C17.5387 4.68298 17.1113 4.68298 16.8477 4.41261L14.85 2.36369Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 10.8462C0 11.2285 0.302208 11.5385 0.675 11.5385H9.225C9.59779 11.5385 9.9 11.2285 9.9 10.8462C9.9 10.4638 9.59779 10.1538 9.225 10.1538H0.675C0.302208 10.1538 0 10.4638 0 10.8462Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 6.23077C0 6.61312 0.302208 6.92308 0.675 6.92308H5.625C5.99779 6.92308 6.3 6.61312 6.3 6.23077C6.3 5.84842 5.99779 5.53846 5.625 5.53846H0.675C0.302208 5.53846 0 5.84842 0 6.23077Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 1.61538C0 1.99774 0.302208 2.30769 0.675 2.30769H3.825C4.19779 2.30769 4.5 1.99774 4.5 1.61538C4.5 1.23303 4.19779 0.923077 3.825 0.923077H0.675C0.302208 0.923077 0 1.23303 0 1.61538Z"
                                                        fill="#00ABFB"
                                                    />
                                                </svg>
                                            </th>
                                            <th id="six">
                                                B Value{" "}
                                                <svg
                                                    width="18"
                                                    height="12"
                                                    viewBox="0 0 18 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.85 2.36369L14.85 11.3077C14.85 11.69 14.5478 12 14.175 12C13.8022 12 13.5 11.69 13.5 11.3077L13.5 2.36369L11.5023 4.41261C11.2387 4.68298 10.8113 4.68298 10.5477 4.41261C10.2841 4.14225 10.2841 3.7039 10.5477 3.43354L13.6977 0.202771C13.9613 -0.0675907 14.3887 -0.0675907 14.6523 0.202771L17.8023 3.43354C18.0659 3.7039 18.0659 4.14225 17.8023 4.41261C17.5387 4.68298 17.1113 4.68298 16.8477 4.41261L14.85 2.36369Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 10.8462C0 11.2285 0.302208 11.5385 0.675 11.5385H9.225C9.59779 11.5385 9.9 11.2285 9.9 10.8462C9.9 10.4638 9.59779 10.1538 9.225 10.1538H0.675C0.302208 10.1538 0 10.4638 0 10.8462Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 6.23077C0 6.61312 0.302208 6.92308 0.675 6.92308H5.625C5.99779 6.92308 6.3 6.61312 6.3 6.23077C6.3 5.84842 5.99779 5.53846 5.625 5.53846H0.675C0.302208 5.53846 0 5.84842 0 6.23077Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 1.61538C0 1.99774 0.302208 2.30769 0.675 2.30769H3.825C4.19779 2.30769 4.5 1.99774 4.5 1.61538C4.5 1.23303 4.19779 0.923077 3.825 0.923077H0.675C0.302208 0.923077 0 1.23303 0 1.61538Z"
                                                        fill="#00ABFB"
                                                    />
                                                </svg>
                                            </th>
                                            <th id="seven">
                                                C Value{" "}
                                                <svg
                                                    width="18"
                                                    height="12"
                                                    viewBox="0 0 18 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.85 2.36369L14.85 11.3077C14.85 11.69 14.5478 12 14.175 12C13.8022 12 13.5 11.69 13.5 11.3077L13.5 2.36369L11.5023 4.41261C11.2387 4.68298 10.8113 4.68298 10.5477 4.41261C10.2841 4.14225 10.2841 3.7039 10.5477 3.43354L13.6977 0.202771C13.9613 -0.0675907 14.3887 -0.0675907 14.6523 0.202771L17.8023 3.43354C18.0659 3.7039 18.0659 4.14225 17.8023 4.41261C17.5387 4.68298 17.1113 4.68298 16.8477 4.41261L14.85 2.36369Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 10.8462C0 11.2285 0.302208 11.5385 0.675 11.5385H9.225C9.59779 11.5385 9.9 11.2285 9.9 10.8462C9.9 10.4638 9.59779 10.1538 9.225 10.1538H0.675C0.302208 10.1538 0 10.4638 0 10.8462Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 6.23077C0 6.61312 0.302208 6.92308 0.675 6.92308H5.625C5.99779 6.92308 6.3 6.61312 6.3 6.23077C6.3 5.84842 5.99779 5.53846 5.625 5.53846H0.675C0.302208 5.53846 0 5.84842 0 6.23077Z"
                                                        fill="#00ABFB"
                                                    />
                                                    <path
                                                        d="M0 1.61538C0 1.99774 0.302208 2.30769 0.675 2.30769H3.825C4.19779 2.30769 4.5 1.99774 4.5 1.61538C4.5 1.23303 4.19779 0.923077 3.825 0.923077H0.675C0.302208 0.923077 0 1.23303 0 1.61538Z"
                                                        fill="#00ABFB"
                                                    />
                                                </svg>
                                            </th>
                                        </tr>
                                    </thead>


                                    <tbody >

                                        {irtList && Array.isArray(irtList?.result) && irtList?.result[0]?.map((items, i) => (
                                            <tr>
                                                <td headers="one"><b><u>{items.question_id}</u></b></td>
                                                <td headers="two">{items['No of time administered']}</td>
                                                <td headers="three">{items['Attempt %']}</td>
                                                <td headers="four">{items['Correct %']}</td>
                                                <td headers="five">{items['A Value']}</td>
                                                <td headers="six">{items['B Value']}</td>
                                                <td headers="seven">{items['C Value']}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <FilterModal usedFor={2} modalFilter={modalFilter} closeModalFilter={closeModalFilter} multiselectedSubjectTag={multiselectedSubjectTag} setmultiSelectedSubjectTag={setmultiSelectedSubjectTag} multiselectedFreeTag={multiselectedFreeTag} setmultiSelectedFreeTag={setmultiSelectedFreeTag} multiselectedAuthorTag={multiselectedAuthorTag} setmultiSelectedAuthorTag={setmultiSelectedAuthorTag} multiselectedCategoryTag={multiselectedCategoryTag} setmultiSelectedCategoryTag={setmultiSelectedCategoryTag} selectedYear={selectedYear} setSelectedYear={setSelectedYear} selectedYearExclude={selectedYearExclude} setSelectedYearExclude={setSelectedYearExclude} currentYear={currentYear} />
        </>
    );
};

export default IRTparameters;
