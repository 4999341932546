import React, { useState } from 'react'
import Login from '../component/molecule/specific/SignInScreens/Login'
import ForgetPassword from '../component/molecule/specific/SignInScreens/ForgetPassword'
import CreateNewPassword from '../component/molecule/specific/SignInScreens/CreateNewPassword'




const SignIn = () => {
    const [screen, setScreen] = useState(0)
    return (
        <div>
            {screen == 0 ? <Login setScreen={setScreen} /> : ""}
            {screen == 1 ? <ForgetPassword setScreen={setScreen} /> : ""}
            {screen == 2 ? <CreateNewPassword setScreen={setScreen} /> : ""}
        </div>
    )
}

export default SignIn