import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import { Routes, Route } from 'react-router-dom';
import QuestionBank from './pages/QuestionBank';
import CreateQuestion from './pages/CreateQuestion';
import EditQuestion from './pages/EditQuestion';
import CreateQuestionSame from './pages/CreateQuestionSame';
import UserManagement from './pages/UserManagement';
import Masters from './pages/Masters';
import OrganisationManagement from './pages/OrganisationManagement';
import SignIn from './pages/SignIn';
import Review from './pages/Review';
import Report from './pages/Report';
import TaskManagement from './pages/TaskManagement';
import TestCreation from './pages/TestCreation';

function App() {

  return (
   <>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/create-question-same/:id" element={<CreateQuestionSame />} />
        <Route path="/question-bank" element={<QuestionBank />} />
        <Route path="/create-question" element={<CreateQuestion />}  />
        <Route path="/edit-question" element={<EditQuestion />}  />
        <Route path="/user-management" element={<UserManagement />}  />
        <Route path="/organisation-management" element={<OrganisationManagement />}  />
        <Route path="/masters" element={<Masters />}  />
        <Route path="/review" element={<Review />}  />
        <Route path='/report' element={<Report />} />
        <Route path='/task-management' element={<TaskManagement />} />
        <Route path='/test-creation' element={<TestCreation />} /> 
        </Routes>
   </>
  );
}

export default App;
