import React, { useEffect, useState } from 'react'
import { CallWithAuth } from '../../../../action/apiActions'
import { GET_QUESTIONBANK } from '../../../../action/apiPath'
import { useDispatch, useSelector } from 'react-redux'
import { CreateQuestionAction, QuestionCreationScreenCancel } from '../../../../redux/action'
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import MediumModal from '../../../atoms/Modal/MediumModal'

import style from './../../../../assets/styles/createQuestion.module.css';

const QuestionBank = ({ id }) => {
    // _________ START : INITILIZATION: REDUX DISPATCH _________
    const dispatch = useDispatch()
    const location= useLocation()
    const selector = useSelector((state) => state.CreateQuestionReducer)
    const navigate = useNavigate();
    // _________ END : INITILIZATION: REDUX DISPATCH _________



    // GET QUESTION BANK API CALL SAVED THE DATA IN STATE 
    const [questionBank, setQuestionBank] = useState(null)

    const getQuestionBank = async () => {
        const GetData = await CallWithAuth("GET", GET_QUESTIONBANK)
        // console.log(GetData)
        if (GetData.status && Array.isArray(GetData.res.data)) {
            setQuestionBank(GetData.res.data)
        }

    }

    useEffect(() => {
        getQuestionBank()
    }, [])



    // SELECT QUESTION BANK SAVED IN QUESTION STATE
    const [question, setQuestion] = useState(null)

    // SELECT QUESTION BANK SAVED  to make status false
    const [saveButtonStatus, setSaveButtonStatus] = useState(false)

    useEffect(() => {
        if (question === null) {
            setSaveButtonStatus(true)
        } else {
            setSaveButtonStatus(false)
        }
    }, [question])


    // _____________ MODAL OPEN AND CLOSE _____________
    const [modalMedium, setModalMedium] = useState(false)
    const [modalData, setModalModalData] = useState({
        "title": "Are You Sure, Do You Want To Cancel Question Creation?",
        "description": "Your Current Changes Will Not Be Saved And You Will Be Redirected To Question Listing ",
        "cancelButton": "Continue Editing",
        "saveButton": "Yes, Cancel"
    })

    const openModalMedium = () => {
        setModalMedium(true)
    }

    const closeModalMedium = () => {
        setModalMedium(false)
    }



    // cancel button to send the user to question-bank 
    const Cancel = () => {
        navigate('/question-bank')
        dispatch(QuestionCreationScreenCancel())
    }


    useEffect(()=>{
        setQuestion(selector.question_bank_id)
    },[selector])

    // console.log("questionBank",questionBank)

    return (
        <>
            <div className={style.select_question}>

                <div className={style.top_bar}>
                    <div className={style.top_bar_title}>
                        <span>Select a Question Bank</span>
                    </div>
                </div>
{/* {selector.screen === 3 ?  `${style.active} ${style.pointer}` : `${style.pointer}` } */}
                <div className={style.listandsave}>
                    {/* _____________  LIST OF QUESTION BANK  _____________ */}
                    <div className={style.list}>
                        {questionBank?.map((items, i) => (
                            <div className={items.id === question ? ` ${style.active_box} ${style.pointer}` : `${style.box} ${style.pointer}`} onClick={() => setQuestion(items.id)}>
                                <div className={style.title_list_box}>
                                    <p>{items.name}</p>
                                    <div className={style.tags}>
                                        {items?.free_tags.map((item, i) => (<>
                                            <button className={style.general_button}>{item.name}</button> &nbsp;
                                        </>

                                        ))}


                                    </div>
                                </div>

                                <div className={style.question_list_box}>
                                    <p>Total Question&nbsp;<span> {items.total_questions}</span></p>
                                </div>

                                <div className={style.largeBall}>


                                    <svg width="72" height="62" viewBox="0 0 72 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="70.5" cy="-8.5" r="70.5" fill="url(#paint0_linear_1_36277)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1_36277" x1="70" y1="-8" x2="22.5" y2="48.5" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#03ADFB" />
                                                <stop offset="1" stop-color="#2CCDFA" />
                                            </linearGradient>
                                        </defs>
                                    </svg>


                                </div>

                                <div className={style.smallBall}>
                                    <svg width="52" height="18" viewBox="0 0 52 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="26" cy="-8" r="26" fill="url(#paint0_linear_1_36278)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1_36278" x1="25.8156" y1="-7.8156" x2="8.29787" y2="13.0213" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#03ADFB" />
                                                <stop offset="1" stop-color="#2CCDFA" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>


                            </div>
                        ))}
                    </div>
                    {/* _____________  LIST OF QUESTION BANK  _____________ */}


                    {/* _____________  SAVE BUTTON  _____________ */}
                    <div className= {style.saveButton_questionBank}>
                        <div className= {` ${style.cancel} ${style.pointer}`} onClick={() =>  openModalMedium()}>
                            Cancel
                        </div>
                        <div>
                            <button className={saveButtonStatus ? ` ${style.blue_button_disable}` : `${style.blue_button}`} disabled={saveButtonStatus} onClick={() => dispatch(CreateQuestionAction(question, id))}>Save & Continue</button>
                        </div>
                    </div>
                    {/* _____________  SAVE BUTTON  _____________ */}
                </div>

                <MediumModal modalMedium={modalMedium} forwardButton={() => Cancel()} openModalMedium={openModalMedium} closeModalMedium={closeModalMedium} modalData={modalData} />

            </div>
        </>
    )
}

export default QuestionBank