const BASE_URL = process.env.REACT_APP_API_KEY

// _____________ AUTHENTICATION _____________
export const GET_AUTHENTICATION= `${BASE_URL}me/`  //GET
export const POST_REFRESH_AUTH= `${BASE_URL}refresh/`  //POST

//_____________ QUESTION CREATION _____________
export const POST_INITIALIZING_QUESTION_CREATION= `${BASE_URL}initialize-question-creation/`  //POST
export const PATCH_SELECT_QUESTION= `${BASE_URL}question-draft/`  //GET
export const GET_FREE_TAG= `${BASE_URL}free-tags/`  //GET
export const GET_QUESTIOB_TYPE= `${BASE_URL}question-types/`  //GET
export const GET_AUTHOR= `${BASE_URL}question-authors/`  //GET


// _____________ QUESTION LISTING _____________
export const GET_QUESTION_BANK= `${BASE_URL}question-bank/`  //GET
export const GET_QUESTION= `${BASE_URL}question/`  //GET
export const GET_QUESTION_DRAFT= `${BASE_URL}question-draft/`  //GET


// _______________ MASTERS _______________
export const GET_CATEGORY= `${BASE_URL}categories/`  //GET
export const CREATE_CATEGORY= `${BASE_URL}create-category/`  //POST
export const EDIT_CATEGORY= `${BASE_URL}categories/`  //PATCH

export const GET_IDENTIFIER= `${BASE_URL}identifiers/`  //GET
export const CREATE_IDENTIFIER= `${BASE_URL}create-identifier/`  //POST
export const EDIT_IDENTIFIER= `${BASE_URL}identifier/`  //PATCH

export const GET_LOD= `${BASE_URL}lod-list/`  //GET
export const CREATE_LOD= `${BASE_URL}create-lod/`  //POST
export const LOD= `${BASE_URL}lod/`  //PATCH

export const GET_QUESTIONBANK= `${BASE_URL}question-banks/`  //GET
export const CREATE_QUESTIONBANK= `${BASE_URL}create-question_bank/`  //POST
export const EDIT_QUESTIONBANK= `${BASE_URL}question-banks/`  //PATCH

export const GET_SUBJECT_TAG= `${BASE_URL}subject-tags/`  //GET
export const CREATE_SUBJECT_TAG= `${BASE_URL}create-subject-tags/`  //POST
export const EDIT_SUBJECT_TAG= `${BASE_URL}subject-tags/`  //PATCH

export const GET_PRODUCT_TYPE_LIST = `${BASE_URL}product-types/` //GET



// ________ USERS ___________
export const GET_USERS= `${BASE_URL}users/`  //GET
export const GET_ROLES= `${BASE_URL}roles/`  //GET
export const CREATE_USERS= `${BASE_URL}create-user/`  //POST


// ___________ organisation ___________
export const GET_ORGANISATION= `${BASE_URL}organizations/`  //GET

//______________ review _________________
export const GET_REVIEW_DATA=  `${BASE_URL}review/question-listing/` 
export const EDIT_REVIEW_STATUS=  `${BASE_URL}review/` 


// ______________ GET_REPORT_DATA ______________
export const GET_REPORT_DATA =`${BASE_URL}reports/view/`



// ________ TASKS ___________
export const GET_TASKS= `${BASE_URL}tasks/`  //GET
// export const GET_ROLES= `${BASE_URL}roles/`  //GET
export const CREATE_TASKS= `${BASE_URL}tasks/create_task/`  //POST
export const GET_TASKS_OF_USER= `${BASE_URL}tasks/fetch_in-progress_tasks/`  //GET



// ________ TEST CREATION ___________
export const CREATE_TESTS_BASIC_SETTING = `${BASE_URL}tests/basic_settings/` //POST
export const CREATE_TESTS_SECTION = `${BASE_URL}tests/` //GET
export const GET_TEST_LISTS = `${BASE_URL}tests/` //GET

export const GET_PRODUCT = `${BASE_URL}product_types/` //GET
export const GET_BROWSE_QUESTION= `${BASE_URL}tests/browse_questions/`  //GET

export const GET_REPLACE_THIS_QUESTION = `${BASE_URL}tests/replace-question/` //GET


export const GET_PDF_DATA=  `${BASE_URL}tests/` 