import React, { useEffect, useRef, useState } from "react";
import TestComm from "../ReviewScreens/TestComm";

import style from "../../../../assets/styles/taskManagement.module.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  Delete_Task,
  Get_Task_Detail,
  Get_Task_Management,
  Task_Screen_Change,
  Task_Screen_Change_With_TaskId,
} from "../../../../redux/action";
import { Route } from "react-router-dom";
import styleModal from "../../../../assets/styles/modalStyle.module.css";
import { GET_TASKS } from "../../../../action/apiPath";
import { CallWithAuth } from "../../../../action/apiActions";

const TaskList = () => {
  const dispatch = useDispatch();
  const { taskDetail } = useSelector((state) => state.GetAllData);


  const [key, setKey] = useState("");
  const [sort, setSort] = useState("");

  /********  Sorting by below */
  const [sortAssignee, setSortAssignee] = useState(true);
  const [sortCreatedDate, setSortCreatedDate] = useState(true);
  const [sortTaskType, setSortTaskType] = useState(true);
  const [sortEndDate, setSortEndDate] = useState(true);
  const [sortStatus, setSortStatus] = useState(true);
  const [sortItems, setSortItems] = useState(true);

  useEffect(() => {
    dispatch(Get_Task_Management(key, sort));
  }, [
    key,
    sort,
    sortCreatedDate,
    sortEndDate,
    sortTaskType,
    sortStatus,
    sortItems,
    dispatch,
  ]);

  const sortFunction = (sorting) => {
    switch (sorting) {
      case "assignees":
        setSortAssignee(!sortAssignee);
        setSort(sortAssignee ? "assignees" : "-assignees");
        break;
      case "created_at":
        setSortCreatedDate(!sortCreatedDate);
        setSort(sortCreatedDate ? "created_at" : "-created_at");
        break;
      case "task_type":
        setSortTaskType(!sortTaskType);
        setSort(sortTaskType ? "task_type" : "-task_type");
        break;
      case "end_date":
        setSortEndDate(!sortEndDate);
        setSort(sortEndDate ? "end_date" : "-end_date");
        break;
      case "status":
        setSortStatus(!sortStatus);
        setSort(sortStatus ? "status" : "-status");
        break;
        case "item":
          setSortItems(!sortItems);
          setSort(sortItems ? "item" : "-item");
          break;
      default:
        break;
    }
  };
/***********  SORTING OVER *******************/
 

  const { taskList } = useSelector((state) => state.GetAllData);

  const [showModal, setShowModal] = useState(false);


  /*** Fetching data for question or test filtering */

  const [type, setType] = useState(null);

  const [questionFilter, setQuestionFilter] = useState([]);

  const questionList = async () => {
    const url = GET_TASKS + "?task_type=" + type;
    // console.log("url", url);
    const fetchedData = await CallWithAuth("GET", url);
    // console.log("Fetched data:", fetchedData);

    if (fetchedData.status && fetchedData.res.status === 200) {
      setQuestionFilter(fetchedData.res.data);
    }
  };

  // console.log("questionFilter type:", typeof questionFilter);
  // console.log("questionFilter is array:", questionFilter);

  useEffect(() => {
    questionList();
  }, [type]);


 /************ Function for switching b/w question and test filters   */
 
 const [reset, setReset] = useState("");
  const [filterReset, setFilterReset] = useState("");
  const handleTypeSelect = (event) => {
    const selectedType = event.target.value;
    let typeValue;
    switch (selectedType) {
      case "Question":
        typeValue = 0;
        break;
      case "Test":
        typeValue = 1;
        break;
      default:
        typeValue = null;
    }
    setType(typeValue);
    setReset(selectedType);
  };




  /*** Fetching data for status filtering */

  const [status, setStatus] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);
  // console.log("My status is: ", status);

  const statusList = async () => {
    const url = GET_TASKS + "?task_status=" + status;
    // console.log("url", url);
    const fetchedStatusData = await CallWithAuth("GET", url);
    // console.log("Fetched data:", fetchedStatusData);

    if (fetchedStatusData.status && fetchedStatusData.res.status === 200) {
      setStatusFilter(fetchedStatusData.res.data);
    }
  };

  // console.log("statusFilter type:", typeof statusFilter);
  // console.log("statusFilter is array:", statusFilter);

  useEffect(() => {
    statusList();
  }, [status]);


   /************ Function for switching b/w status filters   */
  const handleStatusSelect = (event) => {
    const selectedStatus = event.target.value;
    let statusValue;
    switch (selectedStatus) {
      case "In Process":
        statusValue = 0;
        break;
      case "Review":
        statusValue = 1;
        break;
      case "Approved":
        statusValue = 2;
        break;
      default:
        statusValue = null;
    }
    setStatus(statusValue);
    setFilterReset(selectedStatus);
  };




   /******* Function to reset all appplied filters */
  const resetFilters = () => {
    // console.log("Before reset:", { type, status }); // Log the current state before resetting

    setType(null);
    setStatus(null);
    setReset("");
    setFilterReset("");
    
    // console.log("After reset:", { type, status }); // Log the state after resetting
  };



  /*****Function to open edit / delete modal */
  const handleClick = (index) => {
    setShowModal((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const ModalComponent = ({ onClose, taskId }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);

    

    // console.log("ModalComponent props:", { onClose, taskId });
    const handleOutsideClick = (event) => {
      if (event.target.className === "overlay") {
        onClose();
      }
    };

    const handleDelete = () => {
      setShowConfirmation(true);
    };

    const handleConfirmDelete = () => {
      dispatch(Delete_Task(taskId));
      onClose();
    };

    const handleCancelDelete = () => {
      setShowConfirmation(false);
      onClose();
    };

    // __________ outside clickable close modal _______________

    const modalRef = useRef();

    const handleOverlayClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCancelDelete();
      }
    };

    useEffect(() => {
      // Attach the event listener when the component mounts
      document.addEventListener("mousedown", handleOverlayClick);

      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener("mousedown", handleOverlayClick);
      };
    }, [handleOverlayClick]);

    return (
      <div>
        {showConfirmation ? (
          <div className={styleModal.filterModal}>
            <div
              className={` ${styleModal.center_div} ${styleModal.delete}`}
              ref={modalRef}
            >
              {/* TABLE LISTING OF ALL AND MY QUESTION  */}
              <div className={styleModal.master_modal}>
                <div className={styleModal.title_delete}>
                  Are you sure, Do you want to delete ( Task : {taskId} ) ?
                </div>

                <div
                  className={` ${styleModal.saveButton_questionBank} ${styleModal.margin_top_20} ${styleModal.center}`}
                >
                  <div
                    className={` ${styleModal.cancel} ${styleModal.pointer}`}
                    onClick={handleCancelDelete}
                  >
                    Cancel
                  </div>
                  <div>
                    <button
                      className={styleModal.blue_button}
                      onClick={handleConfirmDelete}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="overlay"
            style={{ position: "relative" }}
            ref={modalRef}
            onClick={handleOutsideClick}
          >
            <div className={style.threePin}>
              <>
                <div className={style.editPops}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 14.668H14"
                      stroke="#212529"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                      stroke="#212529"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052"
                      stroke="#212529"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <div
                    className={style.action}
                    onClick={() =>
                      dispatch(Task_Screen_Change_With_TaskId(taskId, 3))
                    }
                  >
                    Edit
                  </div>
                </div>
                <div className={style.editPops}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5669 6.09375L12.1336 12.8071C12.0603 13.8537 12.0003 14.6671 10.1403 14.6671H5.86026C4.00026 14.6671 3.94026 13.8537 3.86693 12.8071L3.43359 6.09375"
                      stroke="#272727"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 3.98763C11.78 3.76763 9.54667 3.6543 7.32 3.6543C6 3.6543 4.68 3.72096 3.36 3.8543L2 3.98763"
                      stroke="#272727"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.66699 3.31398L5.81366 2.44065C5.92033 1.80732 6.00033 1.33398 7.12699 1.33398H8.87366C10.0003 1.33398 10.087 1.83398 10.187 2.44732L10.3337 3.31398"
                      stroke="#272727"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <div className={style.action} onClick={() => handleDelete()}>
                    Delete
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className={style.question_bank}>
        {/* TABLE SECTION OF THIS PAGE  */}
        <div style={{ height: "34rem" }} className={style.table_content_report}>
          <div className={style.reporting_content}>
            {/* _______________________Top content of table_______________________  */}
            <div className={style.top_content_table}>
              {/*_______________________  Search bar  _______________________ */}
              <div className={style.advanced_filter}>
                <div className={style.text_filter}>
                  <p style={{ fontWeight: 600 }}>Filter By</p>
                </div>
                <div className={style.actual_filter}>
                  <div className="1drop">
                    <select
                      style={{ color: "black" }}
                      className={` ${style.dropdown_css} ${style.dropTasks}`}
                      id="searchInput"
                      // onChange={handleQuestionSelect}

                      placeholder="Task type"
                      name="cars"
                    >
                      <option value="">My Tasks</option>
                    </select>
                  </div>
                  <div className="1drop">
                    <select
                      className={` ${style.dropdown_css} ${style.dropTasks}`}
                      id="searchInput"
                      onChange={handleTypeSelect}
                      placeholder="Select your roles"
                      name="cars"
                      value={reset === "" ? "" : reset}
                    >
                      <option value="">Choose Task Type</option>
                      <option value="Question">Question</option>
                      <option value="Test">Test</option>
                    </select>
                  </div>
                  <div className="1drop">
                    <select
                      className={` ${style.dropdown_css} ${style.dropTasks}`}
                      id="searchInput"
                      onChange={handleStatusSelect}
                      value={filterReset === "" ? "" : filterReset}
                    >
                      <option value="">Choose Status</option>

                      <option value="In Process">In Process</option>
                      <option value="Review">Review</option>
                      <option value="Approved">Approved</option>
                    </select>
                  </div>

                  <div onClick={resetFilters} className={style.reverse_drop}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.0001 5.40039C11.022 5.40039 10.1345 5.557 9.32483 5.79899C8.92796 5.91759 8.51009 5.69201 8.39148 5.29514C8.27288 4.89827 8.49846 4.4804 8.89532 4.36179C9.82561 4.08378 10.8582 3.90039 12.0001 3.90039C17.2043 3.90039 21.4201 8.11618 21.4201 13.3204C21.4201 18.5246 17.2043 22.7404 12.0001 22.7404C6.79586 22.7404 2.58008 18.5246 2.58008 13.3204C2.58008 11.3868 3.16704 9.58286 4.16604 8.08437C4.3958 7.73972 4.86146 7.64659 5.2061 7.87635C5.55075 8.10612 5.64388 8.57177 5.41412 8.91642C4.57311 10.1779 4.08008 11.694 4.08008 13.3204C4.08008 17.6962 7.62429 21.2404 12.0001 21.2404C16.3759 21.2404 19.9201 17.6962 19.9201 13.3204C19.9201 8.9446 16.3759 5.40039 12.0001 5.40039Z"
                        fill="#272727"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.2516 1.43431C11.564 1.70627 11.5968 2.18001 11.3248 2.49244L8.43485 5.81244C8.16289 6.12487 7.68915 6.15767 7.37672 5.88571C7.0643 5.61375 7.03149 5.14001 7.30345 4.82758L10.1935 1.50758C10.4654 1.19516 10.9392 1.16235 11.2516 1.43431Z"
                        fill="#272727"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.26342 4.87817C7.50764 4.54361 7.97683 4.47037 8.31139 4.71459L11.6814 7.17459C12.0159 7.41881 12.0892 7.888 11.845 8.22256C11.6007 8.55712 11.1316 8.63036 10.797 8.38614L7.427 5.92614C7.09244 5.68192 7.0192 5.21273 7.26342 4.87817Z"
                        fill="#272727"
                      />
                    </svg>
                    <p>Reset Filter</p>
                  </div>
                </div>
              </div>
              {/*_______________________  filter  _______________________ */}
              <div
                className={` ${style.filter}  ${style.user_management_filter}`}
              ></div>
              {/*_______________________  Add Question Button  _______________________ */}
              <div className={style.add_question}>
                <button
                  className={` ${style.add_new_question} ${style.pointer}`}
                  onClick={() => dispatch(Task_Screen_Change(1))}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      fill="white"
                    />
                    <path
                      d="M7.5 12H16.5"
                      stroke="#00ABFB"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 16.5V7.5"
                      stroke="#00ABFB"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>Create New Task</span>
                </button>
              </div>
            </div>

            {/* TABLE LISTING OF USERS  */}
            <div className={style.sub_head_content_table}>
              <table>
                <thead>
                  <tr>
                    <th
                      style={{ paddingLeft: "5px" }}
                      className={` ${style.first} ${style.pointer}`}
                    >
                      <span>Task ID</span>
                    </th>
                    <th
                      className={` ${style.first} ${style.pointer}`}
                      onClick={() => sortFunction("assignees")}
                    >
                      <span>Assigned To</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill="#00ABFB"
                        />
                      </svg>
                    </th>
                    <th
                      className={` ${style.second} ${style.pointer}`}
                      onClick={() => sortFunction("task_type")}
                    >
                      {" "}
                      <span>Task Type</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill="#00ABFB"
                        />
                      </svg>
                    </th>
                    <th
                      className={` ${style.third} ${style.pointer}`}
                      onClick={() => sortFunction("created_at")}
                    >
                      <span>Created On</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill="#00ABFB"
                        />
                      </svg>
                    </th>
                    <th
                      className={` ${style.fourth} ${style.pointer}`}
                      onClick={() => sortFunction("end_date")}
                    >
                      <span>End Date</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill="#00ABFB"
                        />
                      </svg>
                    </th>
                    <th onClick={() => sortFunction("item")} className={` ${style.fourpointfive} ${style.pointer}`}>
                      <span>Task Item</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill="#00ABFB"
                        />
                      </svg>
                    </th>

                    <th
                      className={` ${style.fifth} ${style.pointer}`}
                      onClick={() => sortFunction("status")}
                    >
                      <span>Status</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2308 2.36369L15.2308 11.3077C15.2308 11.69 14.9208 12 14.5385 12C14.1561 12 13.8462 11.69 13.8462 11.3077L13.8462 2.36369L11.7972 4.41261C11.5269 4.68298 11.0885 4.68298 10.8182 4.41261C10.5478 4.14225 10.5478 3.7039 10.8182 3.43354L14.0489 0.202771C14.3193 -0.0675907 14.7576 -0.0675907 15.028 0.202771L18.2588 3.43354C18.5291 3.7039 18.5291 4.14225 18.2588 4.41261C17.9884 4.68298 17.5501 4.68298 17.2797 4.41261L15.2308 2.36369Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 10.8462C0 11.2285 0.309957 11.5385 0.692308 11.5385H9.46154C9.84389 11.5385 10.1538 11.2285 10.1538 10.8462C10.1538 10.4638 9.84389 10.1538 9.46154 10.1538H0.692308C0.309957 10.1538 0 10.4638 0 10.8462Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 6.23077C0 6.61312 0.309957 6.92308 0.692308 6.92308H5.76923C6.15158 6.92308 6.46154 6.61312 6.46154 6.23077C6.46154 5.84842 6.15158 5.53846 5.76923 5.53846H0.692308C0.309957 5.53846 0 5.84842 0 6.23077Z"
                          fill="#00ABFB"
                        />
                        <path
                          d="M0 1.61538C0 1.99774 0.309957 2.30769 0.692308 2.30769H3.92308C4.30543 2.30769 4.61538 1.99774 4.61538 1.61538C4.61538 1.23303 4.30543 0.923077 3.92308 0.923077H0.692308C0.309957 0.923077 0 1.23303 0 1.61538Z"
                          fill="#00ABFB"
                        />
                      </svg>
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>

                {/* ____________ user Data Listing _____________ */}
                <tbody>
                  {type === null && status === null
                    ? taskList &&
                    Array.isArray(taskList) &&
                    taskList?.map((item, i) => (
                      <tr key={i} className={style.table_body_content}>
                        <td>
                          <div
                            style={{
                              textDecoration: "underline",
                              fontWeight: "600",
                              cursor: "pointer",
                            }}
                            className={
                              style.user_management_table_body_content
                            }
                            onClick={() =>
                              dispatch(Get_Task_Detail(item.task_id))
                            }
                          >
                            {item.task_id}
                          </div>
                        </td>
                        <td>
                          <div
                            className={
                              style.user_management_table_body_content
                            }
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                            }}
                          >
                            {item?.assigned_to
                              ?.slice(0, 2)
                              .map((name, index) => (
                                <span key={index}>
                                  {name}{" "}
                                  {(index === 0) &
                                    (item?.assigned_to.length > 1)
                                    ? ","
                                    : ""}
                                </span>
                              ))}
                            {item?.assigned_to?.length > 2 && (
                              <div
                                style={{
                                  borderRadius: "50%",
                                  width: "32px",
                                  height: "32px",
                                  backgroundColor: "#ECF3F5",
                                  color: "black",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: 500,
                                }}
                              >
                                + {item.assigned_to.length - 2}
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div
                            className={
                              style.user_management_table_body_content
                            }
                          >
                            {item.task_type}
                          </div>
                        </td>
                        <td>
                          <div
                            className={
                              style.user_management_table_body_content
                            }
                          >
                            {moment(item.created_on).format("Do MMM YYYY")}
                          </div>
                        </td>
                        <td>
                          <div
                            className={
                              style.user_management_table_body_content
                            }
                          >
                            {moment(item.end_date).format("Do MMM YYYY")}
                          </div>
                        </td>

                        <td>
                          <div
                            className={
                              style.user_management_table_body_content
                            }
                          >
                            3
                          </div>
                        </td>

                        <td>
                          <div
                            className={
                              style.user_management_table_body_content
                            }
                          >
                            <button className={style.in_review}>
                              {item.status}
                            </button>
                          </div>
                        </td>
                        <td className={style.todo_comment_button}>
                          <button className={style.comm_bt}>
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.0004 1.66797C8.90603 1.66797 7.82239 1.88352 6.81135 2.30231C5.8003 2.7211 4.88164 3.33492 4.10782 4.10875C2.54502 5.67155 1.66704 7.79116 1.66704 10.0013C1.65976 11.9256 2.32604 13.7917 3.55038 15.2763L1.88371 16.943C1.76808 17.0602 1.68975 17.209 1.6586 17.3706C1.62746 17.5323 1.64489 17.6995 1.70871 17.8513C1.77792 18.0012 1.89013 18.1272 2.03108 18.2133C2.17203 18.2993 2.33538 18.3416 2.50038 18.3346H10.0004C12.2105 18.3346 14.3301 17.4567 15.8929 15.8939C17.4557 14.3311 18.3337 12.2114 18.3337 10.0013C18.3337 7.79116 17.4557 5.67155 15.8929 4.10875C14.3301 2.54594 12.2105 1.66797 10.0004 1.66797ZM10.0004 16.668H4.50871L5.28371 15.893C5.43892 15.7368 5.52604 15.5256 5.52604 15.3055C5.52604 15.0853 5.43892 14.8741 5.28371 14.718C4.19253 13.628 3.51302 12.1934 3.36095 10.6586C3.20888 9.12384 3.59366 7.58381 4.44973 6.3009C5.3058 5.018 6.5802 4.0716 8.05581 3.62295C9.53141 3.17429 11.1169 3.25114 12.5422 3.8404C13.9675 4.42965 15.1444 5.49486 15.8724 6.85454C16.6004 8.21422 16.8344 9.78426 16.5346 11.2971C16.2349 12.81 15.4198 14.1722 14.2283 15.1515C13.0368 16.1308 11.5427 16.6668 10.0004 16.668ZM12.5004 9.16797H10.8337V7.5013C10.8337 7.28029 10.7459 7.06833 10.5896 6.91205C10.4334 6.75577 10.2214 6.66797 10.0004 6.66797C9.77936 6.66797 9.5674 6.75577 9.41112 6.91205C9.25484 7.06833 9.16704 7.28029 9.16704 7.5013V9.16797H7.50038C7.27936 9.16797 7.0674 9.25577 6.91112 9.41205C6.75484 9.56833 6.66704 9.78029 6.66704 10.0013C6.66704 10.2223 6.75484 10.4343 6.91112 10.5906C7.0674 10.7468 7.27936 10.8346 7.50038 10.8346H9.16704V12.5013C9.16704 12.7223 9.25484 12.9343 9.41112 13.0906C9.5674 13.2468 9.77936 13.3346 10.0004 13.3346C10.2214 13.3346 10.4334 13.2468 10.5896 13.0906C10.7459 12.9343 10.8337 12.7223 10.8337 12.5013V10.8346H12.5004C12.7214 10.8346 12.9334 10.7468 13.0896 10.5906C13.2459 10.4343 13.3337 10.2223 13.3337 10.0013C13.3337 9.78029 13.2459 9.56833 13.0896 9.41205C12.9334 9.25577 12.7214 9.16797 12.5004 9.16797Z"
                                fill="#272727"
                              />
                              <circle
                                cx="16.6663"
                                cy="4.44271"
                                r="3.83333"
                                fill="#00ABFB"
                                stroke="white"
                              />
                            </svg>
                          </button>
                        </td>

                        <td>
                          <div
                            className="middle"
                            style={{ display: "flex", alignItems: "center" }}
                          ></div>
                          <div>
                            <div className={style.svgFunc}>
                              <svg
                                width="4"
                                height="16"
                                viewBox="0 0 4 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(i)}
                              >
                                <path
                                  d="M2 12C2.53043 12 3.03914 12.2107 3.41421 12.5858C3.78929 12.9609 4 13.4696 4 14C4 14.5304 3.78929 15.0391 3.41421 15.4142C3.03914 15.7893 2.53043 16 2 16C1.46957 16 0.96086 15.7893 0.585787 15.4142C0.210714 15.0391 0 14.5304 0 14C0 13.4696 0.210714 12.9609 0.585787 12.5858C0.96086 12.2107 1.46957 12 2 12ZM2 6C2.53043 6 3.03914 6.21071 3.41421 6.58579C3.78929 6.96086 4 7.46957 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10C1.46957 10 0.96086 9.78929 0.585787 9.41421C0.210714 9.03914 0 8.53043 0 8C0 7.46957 0.210714 6.96086 0.585787 6.58579C0.96086 6.21071 1.46957 6 2 6ZM2 0C2.53043 0 3.03914 0.210714 3.41421 0.585786C3.78929 0.960859 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.96086 3.78929 0.585787 3.41421C0.210714 3.03914 0 2.53043 0 2C0 1.46957 0.210714 0.960859 0.585787 0.585786C0.96086 0.210714 1.46957 0 2 0Z"
                                  fill="#272727"
                                />
                              </svg>
                              {showModal[i] && (
                                <ModalComponent
                                  onClose={() => handleClick(i)}
                                  index={i}
                                  taskId={item.task_id}
                                />
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                    : type === 0
                      ? questionFilter?.map((item, i) => (
                        <tr key={i} className={style.table_body_content}>
                          <td>
                            <div
                              style={{
                                textDecoration: "underline",
                                fontWeight: "600",
                                cursor: "pointer",
                              }}
                              className={
                                style.user_management_table_body_content
                              }
                              onClick={() =>
                                dispatch(Get_Task_Detail(item.task_id))
                              }
                            >
                              {item.task_id}
                            </div>
                          </td>
                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                              }}
                            >
                              {item?.assigned_to
                                ?.slice(0, 2)
                                .map((name, index) => (
                                  <span key={index}>{name}</span>
                                ))}
                              {item?.assigned_to?.length > 2 && (
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    width: "32px",
                                    height: "32px",
                                    backgroundColor: "#ECF3F5",
                                    color: "black",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: 500,
                                  }}
                                >
                                  + {item.assigned_to.length - 2}
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                            >
                              {item.task_type}
                            </div>
                          </td>
                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                            >
                              {moment(item.created_on).format("Do MMM YYYY")}
                            </div>
                          </td>
                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                            >
                              {moment(item.end_date).format("Do MMM YYYY")}
                            </div>
                          </td>

                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                            >
                              3
                            </div>
                          </td>

                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                            >
                              <button className={style.in_review}>
                                {item.status}
                              </button>
                            </div>
                          </td>
                          <td className={style.todo_comment_button}>
                            <button className={style.comm_bt}>
                              <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.0004 1.66797C8.90603 1.66797 7.82239 1.88352 6.81135 2.30231C5.8003 2.7211 4.88164 3.33492 4.10782 4.10875C2.54502 5.67155 1.66704 7.79116 1.66704 10.0013C1.65976 11.9256 2.32604 13.7917 3.55038 15.2763L1.88371 16.943C1.76808 17.0602 1.68975 17.209 1.6586 17.3706C1.62746 17.5323 1.64489 17.6995 1.70871 17.8513C1.77792 18.0012 1.89013 18.1272 2.03108 18.2133C2.17203 18.2993 2.33538 18.3416 2.50038 18.3346H10.0004C12.2105 18.3346 14.3301 17.4567 15.8929 15.8939C17.4557 14.3311 18.3337 12.2114 18.3337 10.0013C18.3337 7.79116 17.4557 5.67155 15.8929 4.10875C14.3301 2.54594 12.2105 1.66797 10.0004 1.66797ZM10.0004 16.668H4.50871L5.28371 15.893C5.43892 15.7368 5.52604 15.5256 5.52604 15.3055C5.52604 15.0853 5.43892 14.8741 5.28371 14.718C4.19253 13.628 3.51302 12.1934 3.36095 10.6586C3.20888 9.12384 3.59366 7.58381 4.44973 6.3009C5.3058 5.018 6.5802 4.0716 8.05581 3.62295C9.53141 3.17429 11.1169 3.25114 12.5422 3.8404C13.9675 4.42965 15.1444 5.49486 15.8724 6.85454C16.6004 8.21422 16.8344 9.78426 16.5346 11.2971C16.2349 12.81 15.4198 14.1722 14.2283 15.1515C13.0368 16.1308 11.5427 16.6668 10.0004 16.668ZM12.5004 9.16797H10.8337V7.5013C10.8337 7.28029 10.7459 7.06833 10.5896 6.91205C10.4334 6.75577 10.2214 6.66797 10.0004 6.66797C9.77936 6.66797 9.5674 6.75577 9.41112 6.91205C9.25484 7.06833 9.16704 7.28029 9.16704 7.5013V9.16797H7.50038C7.27936 9.16797 7.0674 9.25577 6.91112 9.41205C6.75484 9.56833 6.66704 9.78029 6.66704 10.0013C6.66704 10.2223 6.75484 10.4343 6.91112 10.5906C7.0674 10.7468 7.27936 10.8346 7.50038 10.8346H9.16704V12.5013C9.16704 12.7223 9.25484 12.9343 9.41112 13.0906C9.5674 13.2468 9.77936 13.3346 10.0004 13.3346C10.2214 13.3346 10.4334 13.2468 10.5896 13.0906C10.7459 12.9343 10.8337 12.7223 10.8337 12.5013V10.8346H12.5004C12.7214 10.8346 12.9334 10.7468 13.0896 10.5906C13.2459 10.4343 13.3337 10.2223 13.3337 10.0013C13.3337 9.78029 13.2459 9.56833 13.0896 9.41205C12.9334 9.25577 12.7214 9.16797 12.5004 9.16797Z"
                                  fill="#272727"
                                />
                                <circle
                                  cx="16.6663"
                                  cy="4.44271"
                                  r="3.83333"
                                  fill="#00ABFB"
                                  stroke="white"
                                />
                              </svg>
                            </button>
                          </td>

                          <td>
                            <div
                              className="middle"
                              style={{ display: "flex", alignItems: "center" }}
                            ></div>
                            <div>
                              <div className={style.svgFunc}>
                                <svg
                                  width="4"
                                  height="16"
                                  viewBox="0 0 4 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClick(i)}
                                >
                                  <path
                                    d="M2 12C2.53043 12 3.03914 12.2107 3.41421 12.5858C3.78929 12.9609 4 13.4696 4 14C4 14.5304 3.78929 15.0391 3.41421 15.4142C3.03914 15.7893 2.53043 16 2 16C1.46957 16 0.96086 15.7893 0.585787 15.4142C0.210714 15.0391 0 14.5304 0 14C0 13.4696 0.210714 12.9609 0.585787 12.5858C0.96086 12.2107 1.46957 12 2 12ZM2 6C2.53043 6 3.03914 6.21071 3.41421 6.58579C3.78929 6.96086 4 7.46957 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10C1.46957 10 0.96086 9.78929 0.585787 9.41421C0.210714 9.03914 0 8.53043 0 8C0 7.46957 0.210714 6.96086 0.585787 6.58579C0.96086 6.21071 1.46957 6 2 6ZM2 0C2.53043 0 3.03914 0.210714 3.41421 0.585786C3.78929 0.960859 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.96086 3.78929 0.585787 3.41421C0.210714 3.03914 0 2.53043 0 2C0 1.46957 0.210714 0.960859 0.585787 0.585786C0.96086 0.210714 1.46957 0 2 0Z"
                                    fill="#272727"
                                  />
                                </svg>
                                {showModal[i] && (
                                  <ModalComponent
                                    onClose={() => handleClick(i)}
                                    index={i}
                                    taskId={item.task_id}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                      : statusFilter?.map((item, i) => (
                        <tr key={i} className={style.table_body_content}>
                          <td>
                            <div
                              style={{
                                textDecoration: "underline",
                                fontWeight: "600",
                                cursor: "pointer",
                              }}
                              className={
                                style.user_management_table_body_content
                              }
                              onClick={() =>
                                dispatch(Get_Task_Detail(item.task_id))
                              }
                            >
                              {item.task_id}
                            </div>
                          </td>
                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                              }}
                            >
                              {item?.assigned_to
                                ?.slice(0, 2)
                                .map((name, index) => (
                                  <span key={index}>{name}</span>
                                ))}
                              {item?.assigned_to?.length > 2 && (
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    width: "32px",
                                    height: "32px",
                                    backgroundColor: "#ECF3F5",
                                    color: "black",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: 500,
                                  }}
                                >
                                  + {item.assigned_to.length - 2}
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                            >
                              {item.task_type}
                            </div>
                          </td>
                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                            >
                              {moment(item.created_on).format("Do MMM YYYY")}
                            </div>
                          </td>
                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                            >
                              {moment(item.end_date).format("Do MMM YYYY")}
                            </div>
                          </td>

                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                            >
                              3
                            </div>
                          </td>

                          <td>
                            <div
                              className={
                                style.user_management_table_body_content
                              }
                            >
                              <button className={style.in_review}>
                                {item.status}
                              </button>
                            </div>
                          </td>
                          <td className={style.todo_comment_button}>
                            <button className={style.comm_bt}>
                              <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.0004 1.66797C8.90603 1.66797 7.82239 1.88352 6.81135 2.30231C5.8003 2.7211 4.88164 3.33492 4.10782 4.10875C2.54502 5.67155 1.66704 7.79116 1.66704 10.0013C1.65976 11.9256 2.32604 13.7917 3.55038 15.2763L1.88371 16.943C1.76808 17.0602 1.68975 17.209 1.6586 17.3706C1.62746 17.5323 1.64489 17.6995 1.70871 17.8513C1.77792 18.0012 1.89013 18.1272 2.03108 18.2133C2.17203 18.2993 2.33538 18.3416 2.50038 18.3346H10.0004C12.2105 18.3346 14.3301 17.4567 15.8929 15.8939C17.4557 14.3311 18.3337 12.2114 18.3337 10.0013C18.3337 7.79116 17.4557 5.67155 15.8929 4.10875C14.3301 2.54594 12.2105 1.66797 10.0004 1.66797ZM10.0004 16.668H4.50871L5.28371 15.893C5.43892 15.7368 5.52604 15.5256 5.52604 15.3055C5.52604 15.0853 5.43892 14.8741 5.28371 14.718C4.19253 13.628 3.51302 12.1934 3.36095 10.6586C3.20888 9.12384 3.59366 7.58381 4.44973 6.3009C5.3058 5.018 6.5802 4.0716 8.05581 3.62295C9.53141 3.17429 11.1169 3.25114 12.5422 3.8404C13.9675 4.42965 15.1444 5.49486 15.8724 6.85454C16.6004 8.21422 16.8344 9.78426 16.5346 11.2971C16.2349 12.81 15.4198 14.1722 14.2283 15.1515C13.0368 16.1308 11.5427 16.6668 10.0004 16.668ZM12.5004 9.16797H10.8337V7.5013C10.8337 7.28029 10.7459 7.06833 10.5896 6.91205C10.4334 6.75577 10.2214 6.66797 10.0004 6.66797C9.77936 6.66797 9.5674 6.75577 9.41112 6.91205C9.25484 7.06833 9.16704 7.28029 9.16704 7.5013V9.16797H7.50038C7.27936 9.16797 7.0674 9.25577 6.91112 9.41205C6.75484 9.56833 6.66704 9.78029 6.66704 10.0013C6.66704 10.2223 6.75484 10.4343 6.91112 10.5906C7.0674 10.7468 7.27936 10.8346 7.50038 10.8346H9.16704V12.5013C9.16704 12.7223 9.25484 12.9343 9.41112 13.0906C9.5674 13.2468 9.77936 13.3346 10.0004 13.3346C10.2214 13.3346 10.4334 13.2468 10.5896 13.0906C10.7459 12.9343 10.8337 12.7223 10.8337 12.5013V10.8346H12.5004C12.7214 10.8346 12.9334 10.7468 13.0896 10.5906C13.2459 10.4343 13.3337 10.2223 13.3337 10.0013C13.3337 9.78029 13.2459 9.56833 13.0896 9.41205C12.9334 9.25577 12.7214 9.16797 12.5004 9.16797Z"
                                  fill="#272727"
                                />
                                <circle
                                  cx="16.6663"
                                  cy="4.44271"
                                  r="3.83333"
                                  fill="#00ABFB"
                                  stroke="white"
                                />
                              </svg>
                            </button>
                          </td>

                          <td>
                            <div
                              className="middle"
                              style={{ display: "flex", alignItems: "center" }}
                            ></div>
                            <div>
                              <div className={style.svgFunc}>
                                <svg
                                  width="4"
                                  height="16"
                                  viewBox="0 0 4 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClick(i)}
                                >
                                  <path
                                    d="M2 12C2.53043 12 3.03914 12.2107 3.41421 12.5858C3.78929 12.9609 4 13.4696 4 14C4 14.5304 3.78929 15.0391 3.41421 15.4142C3.03914 15.7893 2.53043 16 2 16C1.46957 16 0.96086 15.7893 0.585787 15.4142C0.210714 15.0391 0 14.5304 0 14C0 13.4696 0.210714 12.9609 0.585787 12.5858C0.96086 12.2107 1.46957 12 2 12ZM2 6C2.53043 6 3.03914 6.21071 3.41421 6.58579C3.78929 6.96086 4 7.46957 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10C1.46957 10 0.96086 9.78929 0.585787 9.41421C0.210714 9.03914 0 8.53043 0 8C0 7.46957 0.210714 6.96086 0.585787 6.58579C0.96086 6.21071 1.46957 6 2 6ZM2 0C2.53043 0 3.03914 0.210714 3.41421 0.585786C3.78929 0.960859 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.96086 3.78929 0.585787 3.41421C0.210714 3.03914 0 2.53043 0 2C0 1.46957 0.210714 0.960859 0.585787 0.585786C0.96086 0.210714 1.46957 0 2 0Z"
                                    fill="#272727"
                                  />
                                </svg>
                                {showModal[i] && (
                                  <ModalComponent
                                    onClose={() => handleClick(i)}
                                    index={i}
                                    taskId={taskDetail?.task_id}
                                    
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskList;
