import React, { useEffect, useRef, useState } from "react";
import { GET_USERS } from "../../../../action/apiPath";
import { CallWithAuth } from "../../../../action/apiActions";

import style from "../../../../assets/styles/taskManagement.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Create_Task,
  Get_All_Product_type,
  Task_Screen_Change,
  getUserQuestionBank,
} from "../../../../redux/action";

const AddTask = () => {
  const dispatch = useDispatch();

  //__________________  ADD TASK ___________________
  const [task_type_info, set_task_type_info] = useState(0);
  const [asignees_info, setAsignees_info] = useState([]);
  const [approvers_info, setApprovers_info] = useState([]);
  const [task_details_info, set_task_details_info] = useState("");
  const [end_date_info, set_end_date_info] = useState("");
  const [question_bank_questions_info, set_question_bank_questions_info] =
    useState([]);

  /** QUESTION BANK APIS CALLING */
  const { questionBank } = useSelector((state) => state.GetAllData);

  useEffect(() => {
    dispatch(getUserQuestionBank());
  }, [dispatch]);

  /*** PRODUCT DETAILS API CALLING */
  const { productType } = useSelector((state) => state.GetAllData);
  // console.log("MY TYPE OF PRODUCT IS", productType);

  useEffect(() => {
    dispatch(Get_All_Product_type());
  }, [dispatch]);

  /*** DYNAMICALLY CALLING DROPDOWN DATA FOR QUESTION AND TEST */
  const [selectedRadio, setSelectedRadio] = useState("Question");
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedRadio(selectedValue);
    set_task_type_info(e.target.value === "Question" ? 0 : 1);

    var dataIncoming = [];

    if (selectedValue === "Question") {
      dataIncoming = questionBank;
    } else if (selectedValue === "Test") {
      dataIncoming = productType;
    } else {
      console.error("ERROR");
    }

    setDropdownOptions(dataIncoming);
    // console.log("DATA INCOMING IS", dataIncoming);
  };

  useEffect(() => {
    if (questionBank && productType) {
      handleRadioChange({ target: { value: "Question" } });
    }
  }, [questionBank, productType]);

  /*** ASSIGNEES FROM USERS */
  const [AssigneefreeTaginput, setAssigneefreeTaginput] = useState("");
  const [AssigneefreeTag, setAssigneefreeTag] = useState([]);
  const [AssigneefreeTagStatus, setAssigneefreeTagStatus] = useState(false);

  /*** APPROVALS */
  const [ApprovalsfreeTaginput, setApprovalsfreeTaginput] = useState("");
  const [ApprovalsfreeTag, setApprovalsfreeTag] = useState([]);
  const [ApprovalsfreeTagStatus, setApprovalsfreeTagStatus] = useState(false);

  // CALL GET API FOR getting TAGS
  const AssigneeLists = async (e) => {
    setAssigneefreeTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth("GET", GET_USERS);
      // console.log(data);
      if (data.status && data.res.status === 200) {
        if (data.res.data.length > 0) {
          setAssigneefreeTag(data.res.data);

          setAssigneefreeTagStatus(true);
        } else {
          setAssigneefreeTagStatus(false);
        }
      }
    } else {
      setAssigneefreeTagStatus(false);
    }
  };

  // CALL FOR APPROVERS
  const ApprovalLists = async (e) => {
    setApprovalsfreeTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth("GET", GET_USERS);
      // console.log(data);
      if (data.status && data.res.status === 200) {
        if (data.res.data.length > 0) {
          setApprovalsfreeTag(data.res.data);

          setApprovalsfreeTagStatus(true);
        } else {
          setApprovalsfreeTagStatus(false);
        }
      }
    } else {
      setApprovalsfreeTagStatus(false);
    }
  };

  // select tags from options
  const selectAssigneeTag = (e) => {
    setAsignees_info((current) => [...current, e]);
    setAssigneefreeTagStatus(false);
    setAssigneefreeTaginput("");
    setAssigneefreeTaginput("");
  };

  // console.log("ASSIGNEE IS", AssigneefreeTaginput);

  // select tags from options
  const selectApprovalTag = (e) => {
    setApprovers_info((current) => [...current, e]);
    setApprovalsfreeTagStatus(false);
    setApprovalsfreeTaginput("");
  };

  /*** Cancel button to remove particular assignne or approver */
  const CancelAssigneeButton = (index) => {
    const updatedAsignees = asignees_info.filter((_, i) => i !== index);

    setAsignees_info(updatedAsignees);
  };

  const cancelApprovalButoon = (index) => {
    const updatedApprovers = approvers_info.filter((_, i) => i !== index);
    setApprovers_info(updatedApprovers);
  };

  /*** Local date converter */
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  const formatedDate = end_date_info ? formatDate(new Date(end_date_info)) : "";

  /*** Body for create task API */
  var body = {
    task_type: task_type_info,
    asignees: asignees_info.map((item) => item.id),
    approvers: approvers_info.map((item) => item.id),
    task_details: task_details_info,
    end_date: formatedDate,
    question_bank_questions: question_bank_questions_info,
  };

  /***Fn Selelcting QuestionBank and ProductTest for task creation */
  const handleQuestionSelect = (e, index) => {
    const questionId = e.target.value;
    if (questionId !== "name") {
      set_question_bank_questions_info((prevInfo) => {
        const updatedInfo = [...prevInfo];
        updatedInfo[index] = { ...updatedInfo[index], id: questionId };
        if (selectedRadio === "Question") {
          updatedInfo[index] = { ...updatedInfo[index], id: questionId };
        } else if (selectedRadio === "Test") {
          updatedInfo[index] = {
            ...updatedInfo[index],
            id: "product_type_tests",
          };
        }
        return updatedInfo;
      });
    } else {
      // console.log("No question selected.");
    }
  };

  /*** Fn for Selelcting number of questions for task creation */
  const NumOfQuestions = (e, index) => {
    const questionNumber = e.target.value;
    set_question_bank_questions_info((prevInfo) => {
      const updatedInfo = [...prevInfo];
      updatedInfo[index] = { ...updatedInfo[index], quesions: questionNumber };
      return updatedInfo;
    });
  };

  /*** Multiple Question numbers and questionbank function */
  const [questionCount, setQuestionCount] = useState(1);

  const deleteQuestion = (index) => {
    set_question_bank_questions_info((prevInfo) => {
      const updatedInfo = [...prevInfo];
      updatedInfo.splice(index, 1); // Remove the question at the specified index
      return updatedInfo;
    });
    setQuestionCount(questionCount - 1); // Decrease the questionCount by 1
  };

  const addQuestion = () => {
    setQuestionCount(questionCount + 1);
    set_question_bank_questions_info((prevInfo) => [...prevInfo]);
  };

  /*** User cannot select gone by dates */
  const [date, setDate] = useState("");

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDate(today);
  }, []);

  return (
    <>
      <div>
        <div className={style.top_section}>
          <span
            style={{ cursor: "pointer" }}
            className={style.left}
            onClick={() => dispatch(Task_Screen_Change(0))}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5488 19.422L9.02883 12.902C8.25883 12.132 8.25883 10.872 9.02883 10.102L15.5488 3.58203"
                stroke="#212529"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Back
          </span>
        </div>

        <div
          className={style.table_content_report}
          style={{ overflowX: "hidden", background:"#ffffff" }}
        >
          <div
            style={{ display: "flex", gap: "5px" }}
            className={` ${style.top_content_table} ${style.profile_title_div}`}
          >
            <span style={{ color: "#8E9EAB" , fontSize:"17px"}}>Task ID </span> 
            <span> &nbsp;123456</span>
          </div>

          <div className={` ${style.select_question} ${style.profile_status}`}>
            <div className={style.info}>
              {/* format  */}
              <div className={style.format}>
                <div className={style.format_title}>Create new task for</div>
                <div className={style.format_choice}>
                  <div>
                    <input
                      type="radio"
                      className={style.pointer}
                      id="question"
                      onChange={(e) => handleRadioChange(e)}
                      name="choose_question"
                      value="Question"
                      checked={selectedRadio === "Question"}
                    />
                    <label htmlFor="question">Question's</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      className={style.pointer}
                      id="test"
                      onChange={(e) => handleRadioChange(e)}
                      name="choose_question"
                      value="Test"
                      checked={selectedRadio === "Test"}
                    />
                    <label htmlFor="test">Test's</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.user_profile}>
            <div className={style.tags}>
              <div className={style.subject}>
                <p>
                  Assignee's <b className={style.red_mendantory}>*</b>{" "}
                </p>
                <input
                  className={style.input_search}
                  type="search"
                  id="searchInput"
                  placeholder="Enter your full name"
                  value={AssigneefreeTaginput}
                  onChange={(e) => AssigneeLists(e.target.value)}
                />
              </div>
              <div className={style.subject}>
                <p>Assign Approver </p>
                <input
                  type="search"
                  className={style.input_search}
                  id="searchInput"
                  placeholder="Enter Approval name"
                  value={ApprovalsfreeTaginput}
                  onChange={(e) => ApprovalLists(e.target.value)}
                />
              </div>
            </div>
            <div className={style.try}>
              {/* this is input field for  searching */}
              <div style={{ width: "30%" }} className={style.input_search}>
                {/* Selected Assignees */}
                {asignees_info?.map((items, i) => (
                  <>
                    <span
                      className={`${style.selected_tags} ${style.asignees}`}
                    >
                      {items.full_name}{" "}
                      <div className={style.cancel_button}>
                        <button
                          className={style.pointer}
                          onClick={() => CancelAssigneeButton(i)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.75781 7.75781L16.2431 16.2431"
                              stroke="#272727"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.75691 16.2431L16.2422 7.75781"
                              stroke="#272727"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </span>
                  </>
                ))}

                {/* list of items of pre-defined multifree tag  */}
                {AssigneefreeTagStatus ? (
                  <div className={style.list_input_primary}>
                    {AssigneefreeTag?.filter(
                      (item) =>
                        !approvers_info.some(
                          (selectedItem) =>
                            selectedItem.full_name === item.full_name
                        )
                    ).map((items, i) => (
                      <>
                        <div
                          className={style.pointer}
                          onClick={() => selectAssigneeTag(items)}
                        >
                          <span>{items.full_name} </span>
                        </div>
                      </>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* this is input field for approval searching */}
              <div style={{ width: "30%" }} className={style.input_search_ok} x>
                {/* Selected Approvals */}
                {approvers_info?.map((items, i) => (
                  <>
                    <span
                      className={`${style.selected_tags} ${style.asignees}`}
                    >
                      {items.full_name}{" "}
                      <div className={style.cancel_button}>
                        <button
                          className={style.pointer}
                          onClick={() => cancelApprovalButoon(i)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.75781 7.75781L16.2431 16.2431"
                              stroke="#272727"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.75691 16.2431L16.2422 7.75781"
                              stroke="#272727"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </span>
                  </>
                ))}

                {/* list of items of pre-defined multifree tag  */}
                {ApprovalsfreeTagStatus ? (
                  <div className={style.list_input_primary}>
                    {ApprovalsfreeTag?.filter(
                      (item) =>
                        !asignees_info.some(
                          (selectedItem) =>
                            selectedItem.full_name === item.full_name
                        )
                    ).map((items, i) => (
                      <>
                        <div
                          className={style.pointer}
                          onClick={() => selectApprovalTag(items)}
                        >
                          <span>{items.full_name} </span>
                        </div>
                      </>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className={style.user_profile}>
            <div className={style.tags}>
              <div className={style.subject}>
                <p>
                  End Date <b className={style.red_mendantory}>*</b>{" "}
                </p>
                <input
                  type="date"
                  className={` ${style.input_search} ${style.date_icon}`}
                  id="searchInput"
                  onChange={(e) => set_end_date_info(e.target.value)}
                  min={date}
                  placeholder="DD-MM-YY"
                />
              </div>
            </div>
          </div>

          <div className={style.user_profile}>
            {[...Array(questionCount)].map((_, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "60px",
                }}
                key={index}
                placeholder={`Question ${index + 1}`}
                // style={{ display: "flex", gap: "60px" }}
                className={style.tags}
              >
                <div className={style.subject}>
                  <p>
                    No. of Questions <b className={style.red_mendantory}>*</b>
                  </p>
                  <input
                    className={style.input_search}
                    type="number"
                    id="searchInput"
                    // onChange={NumOfQuestions}
                    onChange={(e) => NumOfQuestions(e, index)}
                    placeholder="Enter"
                  />
                </div>
                <div className={style.subject}>
                  <p>
                    Question Bank <b className={style.red_mendantory}>*</b>
                  </p>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                    className={style.header_drop}
                  >
                    <select
                      className={style.dropdown_css}
                      id="searchInput"
                      // onChange={handleQuestionSelect}
                      onChange={(e) => handleQuestionSelect(e, index)}
                      placeholder="Select your roles"
                      name="cars"
                    >
                      <option value="name">Select a question</option>
                      {dropdownOptions?.map((question, index) => (
                        <option key={index} value={question.id}>
                          {question.name}
                        </option>
                      ))}
                    </select>

                  
                  </div>
                </div>

                {questionCount > 1 && (
                      index===0?"":
                      <button
                        style={{
                          // width: "80px",
                          // height: "40px",
                          borderRadius: "16px",
                          border: "none",
                          background: "#F6F7FB",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          cursor: "pointer",
                          position:"relative",
                          top:"20px",
                          padding:"10px 20px 10px 20px"
                        }}
                        onClick={() => deleteQuestion(index)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.8499 9.14062L18.1999 19.2106C18.0899 20.7806 17.9999 22.0006 15.2099 22.0006H8.7899C5.9999 22.0006 5.9099 20.7806 5.7999 19.2106L5.1499 9.14062"
                            stroke="#272727"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                            stroke="#272727"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                            stroke="#272727"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span style={{ fontSize: "12px" }}>Remove</span>
                      </button>
                    )}
                    
              </div>
            ))}

            <div className={style.Add_answer}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#00ABFB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.5 12H16.5"
                  stroke="#00ABFB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 16.5V7.5"
                  stroke="#00ABFB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style={{ cursor: "pointer" }} onClick={addQuestion}>
                Add More Questions to Task
              </span>
            </div>
          </div>

          <div className={style.user_profile}>
            <div className={style.tags}>
              <div className={style.subject}>
                <p>Task Detail </p>
                <textarea
                  style={{
                    width: "673px",
                    height: "110px",
                    borderRadius: "12px",
                    resize: "none",
                    padding: "10px",
                    outline: "none",
                    border: "1.5px solid #ECF3F5",
                    marginBottom: "2em",
                  }}
                  className={style.input_search}
                  type="search"
                  id="searchInput"
                  onChange={(e) => set_task_details_info(e.target.value)}
                  placeholder="Enter task details"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={` ${style.saveButton_questionBank} ${style.margin_top_20}`}
        >
          <div
            className={` ${style.cancel} ${style.pointer}`}
            onClick={() => dispatch(Task_Screen_Change(0))}
          >
            Cancel
          </div>
          <div>
            <button
              className={style.blue_button}
              onClick={() => dispatch(Create_Task(body))}
            >
              Assign Task
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTask;
