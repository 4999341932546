import React, { useState } from "react";
import AuthLayout from "../layout/AuthLayout";
import RightPanelTopMenu from "../component/molecule/common/RightPanelTopMenu";
import QuestionDistributionReport from "../component/molecule/specific/ReportsScreens/QuestionDistributionReport";
import IRTparameters from "../component/molecule/specific/ReportsScreens/IRTparameters";

const Report = () => {
    // _________Dropdown Action State to visit diffrent Screens of Reports _________
    const [reportSelection, setReportSelection] = useState(1);
 
    return (
        <AuthLayout>
            {/* rightpannel top left menu according to different screen  */}
            <RightPanelTopMenu
                setQuestionBankId={setReportSelection}
                title={"Reports"}
            />
                {/*  Different Screens for Reports according to the selection perform inside the dropdwon action */}
                <div>
                    {reportSelection ===  1? <QuestionDistributionReport /> : ""}
                    {reportSelection ===  2? <IRTparameters /> : ""}
                </div>
        </AuthLayout>
    );
};

export default Report;
