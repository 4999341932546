import React, { useEffect, useRef } from 'react'
import { CREATE_CATEGORY, GET_CATEGORY, GET_IDENTIFIER, GET_QUESTIONBANK, GET_SUBJECT_TAG, LOD } from '../../../action/apiPath'
import { CallWithAuth } from '../../../action/apiActions'



import style from '../../../assets/styles/modalStyle.module.css';
import { useDispatch } from 'react-redux';
import { Get_All_Category, Get_All_Identifier, Get_All_LOD, Get_All_Question_bank, Get_All_Subject_pipeline } from '../../../redux/action';

const MastersDeleteModal = ({ modal, closeModal, deleteitem, FunctionCall, type }) => {
    const dispatch = useDispatch()

    const DeleteCategory = async () => {

        if (type === "Category") {
            const data = await CallWithAuth("DELETE", GET_CATEGORY + deleteitem.id + "/delete-category/")
            // console.log(data)
            if (data.res.status === 204) {
                closeModal()
                dispatch(Get_All_Category("",""))
            }
        }
        if (type === "Identifier") {
            const data = await CallWithAuth("DELETE", GET_IDENTIFIER + deleteitem.id + "/delete-identifier/")
            // console.log(data)
            if (data.res.status === 204) {
                closeModal()
                dispatch(Get_All_Identifier("",""))
            }
        }
        if (type === "LOD") {
            const data = await CallWithAuth("DELETE", LOD + deleteitem.id + "/delete-lod/")
            // console.log(data)
            if (data.res.status === 204) {
                closeModal()
                dispatch(Get_All_LOD("",""))
            }
        }

        if (type === "QuestionBank") {
            const data = await CallWithAuth("DELETE", GET_QUESTIONBANK + deleteitem.id + "/delete-question-bank/")
            // console.log(data)
            if (data.res.status === 204) {
                closeModal()
                dispatch(Get_All_Question_bank("",""))
            }
        }

        if (type === "SubjectPipeline") {
            const data = await CallWithAuth("DELETE", GET_SUBJECT_TAG + deleteitem.id + "/delete-subject-tag/")
            // console.log(data)
            if (data.res.status === 204) {
                closeModal()
                dispatch(Get_All_Subject_pipeline("",""))
            }
        }


    }


    // __________ outside clickable close modal _______________

    const modalRef = useRef();

    const handleOverlayClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal()
        }
    };

    useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener('mousedown', handleOverlayClick);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleOverlayClick);
        };
    }, [handleOverlayClick]);



    return (
        <>
            {modal ?
                <>
                    {type === "SubjectPipeline" ?
                        <div className={style.filterModal}>
                            <div className={` ${style.center_div} ${style.deleteSubjectPipeline}`} ref={modalRef}>
                                {/* TABLE LISTING OF ALL AND MY QUESTION  */}
                                <div className={style.master_modal}>
                                    <div className={style.title_delete}>
                                        Are you sure, Do you want to delete Subject | Area | Topic | Sub-Topic ?
                                    </div>
                                    <div className={style.Sub_title_delete}>
                                        This tag will be deleted from all tests and questions having this tag
                                    </div>

                                    <div className={style.Warning_title_delete}>
                                        You can replace the tag with new tags.
                                    </div>
                                    <div className={` ${style.saveButton_questionBank} ${style.margin_top_20} ${style.center}`}>
                                        <div className={` ${style.cancel} ${style.pointer}`} onClick={() => closeModal()}>
                                            No, Replace Tags
                                        </div>
                                        <div>
                                            <button className={style.blue_button} onClick={() => DeleteCategory()}>Yes, Confirm Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={style.filterModal}>
                            <div className={` ${style.center_div} ${style.delete}`} ref={modalRef}>
                                {/* TABLE LISTING OF ALL AND MY QUESTION  */}
                                <div className={style.master_modal}>
                                    <div className={style.title_delete}>
                                        Are you sure, Do you want to delete {deleteitem.name} ?
                                    </div>

                                    <div className={` ${style.saveButton_questionBank} ${style.margin_top_20} ${style.center}`}>
                                        <div className={` ${style.cancel} ${style.pointer}`} onClick={() => closeModal()}>
                                            Cancel
                                        </div>
                                        <div>
                                            <button className={style.blue_button} onClick={() => DeleteCategory()}>Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
                : ""}
        </>
    )
}

export default MastersDeleteModal